import React from "react";
import { ReactComponent as Arrow } from "@svgs/arrow.svg"

const CustomArrow = ({ id, classList, onClick, fill, ariaLabel }) => {
  return (
    <button id={id} className={`arrow-small ${classList}`}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      <Arrow fill={fill || "white"} />
    </button>
  );
}

export default CustomArrow;