import { distance as levenshteinDistance, closest as levenshteinClosest } from 'fastest-levenshtein';

const useSearchQueries = () => {

  const similarWords = {
    words: [],
    isValid: true,
  };

  const filterItemsByQuery = (items, query, returnSimilarWords) => {
    const matchedItems = items?.filter(item => {
      const titleWords = item?.title?.toLowerCase().split(" ");
      const matchWords = titleWords?.filter(word => +levenshteinDistance(query, word) <= 2);
      const hasPartialMatch = matchWords?.length > 0;
      const hasExactMatch = item?.title?.toLowerCase().includes(query?.toLowerCase());

      if (returnSimilarWords && query !== '' && hasPartialMatch && similarWords.isValid) {
        if (hasExactMatch) similarWords.isValid = false;

        matchWords.forEach(word => {
          if (!similarWords.words.includes(word)) similarWords.words.push(word);
        });
      }

      return query !== '' && (hasExactMatch || hasPartialMatch);
    });

    return matchedItems;
  };
  
  const getItems = (query, data) => {
    const items = {};
  
    Object.keys(data)?.forEach((key) => {
      items[key] = filterItemsByQuery(data[key], query, true);
    });

    if (similarWords.words.length > 0 && similarWords.isValid) {
      const closestWordToQuery = levenshteinClosest(query, similarWords.words);
      return { items, closestWordToQuery }
    }
  
    return { items };
  };

  const getLatestItems = (queries, data, latestData) => {
    const items = {};

    if (queries?.length === 0) {
      return latestData;
    }

    queries?.forEach((query) => {
      Object.keys(data)?.forEach((key) => {
        items[key] = items[key] || [];

        const filteredItems = filterItemsByQuery(data[key], query).slice(0, 6);

        items[key].unshift(...filteredItems);
      });
    });

    Object.keys(items).forEach((key) => {
      if (items[key].length < 6 && latestData[key]?.length > 0) {
        const missingItems = 6 - items[key].length;

        const newItems = latestData[key]?.filter(
          (item) => !items[key].find((i) => i._id === item._id)
        ).slice(0, missingItems);

        items[key].push(...newItems);
      }

      items[key] = Array.from(new Set(items[key]));
    });


    return items;
  }

  return { getItems, getLatestItems }
}

export default useSearchQueries