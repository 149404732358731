import React from 'react'
import { Link } from 'gatsby'
import { ReactComponent as Arrow } from "@svgs/arrow.svg"
import { ReactComponent as ArrowDown } from "@svgs/arrow-down.svg";
import { desktopButtonWidth, mobileButtonWidth, setButtonTheme, setButtonWidth } from './helpers'
import { linkProps } from '@utils/general'

const Button = ({ 
	id,
	theme = {
		desktop: 'btn-primary',
		mobile: 'btn-primary'
	}, 
	internalLink, 
	externalLink, 
	type = '',
	title,
	buttonType,
	ctaWidth = {
		desktopWidth: 'fit',
		mobileWidth: 'fit',
	}, 
	arrow = 'right', 
	onClick, 
	className, 
	children, 
	text, 
	disabled = false, 
	target,
	tabIndex,
	ariaLabel,
}) => {
	const { to, href }  = {...linkProps(internalLink, externalLink)}

	if (!to && !href && !children && !text) return

	const themeStyle = setButtonTheme(theme)
	const width = setButtonWidth(ctaWidth)

	if (buttonType || onClick) {
		return (
			<>
				<button id={id ? id + "-mobile" : null} type={buttonType} className={`btn ${themeStyle?.mobile || ''} w-full ${mobileButtonWidth[width?.mobileWidth] || ''} ${className || ''} flex items-center md:hidden`} onClick={onClick} disabled={disabled} tabIndex={tabIndex} aria-label={ariaLabel}>
					{children || text}
					{arrow !== 'none' && (arrow === 'down' ? <ArrowDown /> : <Arrow className={`ml-2 arrow ${arrow === 'left' ? 'rotate-180' : ''}`} />)}
				</button>
				<button id={id || null} type={buttonType} className={`btn ${themeStyle?.desktop || ''} w-full ${desktopButtonWidth[width?.desktopWidth] || ''} ${className || ''} flex items-center max-md:hidden`} onClick={onClick} disabled={disabled} tabIndex={tabIndex} aria-label={ariaLabel}>
					{children || text}
					{arrow !== 'none' && (arrow === 'down' ? <ArrowDown /> : <Arrow className={`ml-2 arrow ${arrow === 'left' ? 'rotate-180' : ''}`} />)}
				</button>
			</>
		)
	}
	return (
		<>
			<Link id={id ? id + "-mobile" : null} to={to} href={href} type={type} target={target} title={title} className={`btn ${themeStyle?.mobile || ''} w-full ${mobileButtonWidth[width?.mobileWidth] || ''} ${className || ''} flex items-center md:hidden`} tabIndex={tabIndex} aria-label={ariaLabel}>
				{children || text}
				{arrow !== 'none' && (arrow === 'down' ? <ArrowDown /> : <Arrow className={`ml-2 arrow ${arrow === 'left' ? 'rotate-180' : ''}`} />)}
			</Link>
			<Link id={id || null} to={to} href={href} type={type} target={target} title={title} className={`btn ${themeStyle?.desktop || ''} w-full ${desktopButtonWidth[width?.desktopWidth] || ''} ${className || ''} flex items-center max-md:hidden`} tabIndex={tabIndex} aria-label={ariaLabel}>
				{children || text}
				{arrow !== 'none' && (arrow === 'down' ? <ArrowDown /> : <Arrow className={`ml-2 arrow ${arrow === 'left' ? 'rotate-180' : ''}`} />)}
			</Link>
		</>
	)
}

export default Button