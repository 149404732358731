exports.components = {
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-view-js": () => import("./../../../src/templates/blog-view.js" /* webpackChunkName: "component---src-templates-blog-view-js" */),
  "component---src-templates-bundle-product-view-js": () => import("./../../../src/templates/bundle-product-view.js" /* webpackChunkName: "component---src-templates-bundle-product-view-js" */),
  "component---src-templates-collection-view-index-js": () => import("./../../../src/templates/collection-view/index.js" /* webpackChunkName: "component---src-templates-collection-view-index-js" */),
  "component---src-templates-preview-index-js": () => import("./../../../src/templates/preview/index.js" /* webpackChunkName: "component---src-templates-preview-index-js" */),
  "component---src-templates-product-view-js": () => import("./../../../src/templates/product-view.js" /* webpackChunkName: "component---src-templates-product-view-js" */),
  "component---src-templates-resource-view-js": () => import("./../../../src/templates/resource-view.js" /* webpackChunkName: "component---src-templates-resource-view-js" */),
  "component---src-templates-resources-list-js": () => import("./../../../src/templates/resources-list.js" /* webpackChunkName: "component---src-templates-resources-list-js" */),
  "component---src-templates-standalone-page-js": () => import("./../../../src/templates/standalone-page.js" /* webpackChunkName: "component---src-templates-standalone-page-js" */)
}

