import React, { useEffect, useState } from "react"
import { findInitialVariant } from "@src/utils/product"
import { getImageData } from "@utils/image"
import { formatPrice } from "@utils/products/pricing"
import { Link } from "gatsby"
import { getUriFromDocument } from "@utils/routing"
import Image from "@src/components/image"
import { useCartStore, useQuickViewStore } from "@src/hooks/useStore"
import useToggleStates from "@src/hooks/useToggleStates"

const UpsellItem = ({ product }) => {
  const {
    _type,
    shortTitle,
    title,
    mainImage,
    matchingVariants,
    slug,
    isBundle,
    productGroups,
    variants,
  } = product

  const firstVariant =
    findInitialVariant(matchingVariants, false) ||
    matchingVariants?.[0] ||
    variants?.[0]
  const [selectedVariant, setSelectedVariant] = useState(firstVariant)
  const variantSlug = `?variantId=${selectedVariant?.shopifyId}`
  const productUrl =
    getUriFromDocument({ slug, productGroups, _type }) +
    (!isBundle ? variantSlug : "")

  const { listingImage } = selectedVariant
  let imageData = getImageData(mainImage, {
    width: 100,
    height: 100,
    fit: "crop",
  })
  let imageAlt = mainImage?.asset?.altText
    ? mainImage?.asset?.altText
    : mainImage?.asset?.filename
        ?.replace(/\.(.*)$/, "")
        .replace(/[^a-zA-Z0-9]+/g, " ")

  const { loading } = useCartStore(["loading"])

  const { toggleAllFunction } = useToggleStates()

  if (listingImage) {
    imageData = getImageData(listingImage, {
      width: 100,
      height: 100,
      fit: "crop",
    })
    imageAlt = listingImage?.asset?.altText
      ? listingImage?.asset?.altText
      : listingImage?.asset?.filename
          ?.replace(/\.(.*)$/, "")
          .replace(/[^a-zA-Z0-9]+/g, " ")
  }

  useEffect(() => {
    setSelectedVariant(firstVariant)
  }, [product])

  const {
    setQuickViewOpen,
    setQuickViewProduct,
    setQuickViewVariant,
    setQuickViewType,
    setQuickViewProductList,
  } = useQuickViewStore([
    "setQuickViewOpen",
    "setQuickViewProduct",
    "setQuickViewVariant",
    "setQuickViewType",
    "setQuickViewProductList",
  ])

  const openProductQuickView = () => {
    setQuickViewProduct(product)
    setQuickViewVariant(selectedVariant)
    setQuickViewOpen(true)
    setQuickViewType("product")
    setQuickViewProductList("Shopping Cart")
  }

  const selectedOptions = selectedVariant?.selectedOptions
    ?.filter(({ value }) => value !== "Default Title")
    ?.slice(0, 2)
    ?.map((option, index, arr) => (
      <React.Fragment key={`${option.name}-${index}`}>
        <span className="flex gap-1">
          <span className="font-medium">{option.name}:</span>
          <span>{option.value}</span>
        </span>
        {index !== arr.length - 1 ? <span className="px-1">/</span> : ""}
      </React.Fragment>
    ))

  return (
    <div
      className={`flex flex-wrap w-full p-5 bg-calm-yellow sm:mb-0 rounded-xl`}
      aria-label={"Upsell Item"}
      tabIndex={0}
    >
      <div className={`flex flex-nowrap w-full`}>
        <div className={`flex flex-grow`}>
          <div className="max-w-20 max-h-20 md:max-w-24 md:max-h-24 w-full h-full rounded-xl overflow-hidden">
            <Link onClick={() => { toggleAllFunction("drawer", false)}} to={productUrl}>
              <Image
                className={
                  "max-w-20 max-h-20 md:max-w-24 md:max-h-24 w-full h-full"
                }
                alt={imageAlt}
                image={imageData}
              />
            </Link>
          </div>
          <div className="flex flex-col ml-2 md:flex-grow justify-between">
            <p className="text-sm text-black w-[75%]">
              <Link onClick={() => { toggleAllFunction("drawer", false)}} to={productUrl}>
                <strong className="font-medium">
                  Frequently purchased with {shortTitle || title}
                </strong>
              </Link>
              <br />
              <span className="flex flex-wrap">{selectedOptions}</span>
            </p>
            <button
              className={`upsell-item-button btn-soft-black hidden max-w-full w-full md:inline-flex btn`}
              type="button"
              disabled={loading}
              onClick={openProductQuickView}
              aria-label={`Select Upsell Options, starting from ${product?.priceFrom} dollars`}
            >
              <span>Select Options</span>
              {formatPrice({ price: product?.priceFrom })}+
            </button>
          </div>
        </div>
      </div>
      <button
        className={`upsell-item-button inline-flex w-full max-w-full md:hidden mt-3 btn btn-primary`}
        type="button"
        disabled={loading}
        onClick={openProductQuickView}
        aria-label={"Select Upsell Options"}
      >
        <span>Select Options</span>
        {formatPrice({ price: product?.priceFrom })}+
      </button>
    </div>
  )
}

export default UpsellItem
