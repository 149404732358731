/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import { GatsbyBrowser } from 'gatsby';
import React from "react"
import Bugsnag from "@bugsnag/js"
import BugsnagPluginReact from "@bugsnag/plugin-react"
import geoRedirect from "./src/utils/geo-redirect";
import { storeVisitorInfo } from "./src/analytics/visitorInfo";
import { setCjCookieFromUrl } from "./src/utils/cj";
import { wrapPageElement as WrapPageElement } from './src/wrapPageElement';
import { trackPageViewCJ, trackUserData } from './src/services/analytics';
import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-pluralrules/polyfill'
import '@formatjs/intl-pluralrules/locale-data/en'
import '@formatjs/intl-numberformat/polyfill'
import '@formatjs/intl-numberformat/locale-data/en'
import "./src/styles/global.css"

Bugsnag.start({
  apiKey: '696c343eac76c7d75f353254e2358fcb',
  plugins: [new BugsnagPluginReact()]
})

window.Bugsnag = Bugsnag

const ErrorBoundary = Bugsnag.getPlugin('react')
  .createErrorBoundary(React)

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = (props) => {
  return (
    <WrapPageElement {...props} />
  );
}

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = ({ element }) => (
  <ErrorBoundary>
    {element}
  </ErrorBoundary>
)

const retriggerKlaviyo = () => {
  const checkForKlaviyoRetrigger = document.querySelector('.klaviyo-retrigger');

  if (checkForKlaviyoRetrigger) {
    let tries = 0;
    const interval = setInterval(() => {
      const klaviyoScript = document.querySelector("script[src='//static.klaviyo.com/onsite/js/klaviyo.js?company_id=KBXfpc']");

      if (klaviyoScript) {
        klaviyoScript?.remove();

        const allScripts = document.getElementsByTagName("script");
        const lastScript = allScripts[allScripts.length - 1]
        lastScript.parentNode?.insertBefore(klaviyoScript, lastScript);

        clearInterval(interval)
      } else if (tries >= 50) {
        clearInterval(interval)
      }
      tries += 1;
    }, 50)
  }
}

// You can delete this file if you're not using it
export const onRouteUpdate = ({ location }, options) => {
  const { pathname } = location
  retriggerKlaviyo();

  window.ElevarDataLayer = window.ElevarDataLayer || [];

  // Trigger normal pageview event
  // window.ElevarDataLayer.push({ event: "gatsby-route-change" })
  window.ElevarInvalidateContext?.()

  const cartInfos = JSON.parse(localStorage.getItem('cartInfos') || 'null');

  trackUserData(cartInfos);
  trackPageViewCJ(cartInfos, pathname);
}

export const onClientEntry = () => {
  geoRedirect();
  setCjCookieFromUrl();
  storeVisitorInfo(window.location.search, window.location.pathname);
}