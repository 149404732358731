import { GatsbyBrowser } from 'gatsby';
import React from 'react';
import Layout from '@layouts/layout.js';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = (data) => {
	const { props, element } = data;

	return(
		<>
			<Layout props={props}>{element}</Layout>
		</> 
	)
};
