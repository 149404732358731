import React from "react"
import Button from "@components/button"
import { getLinkDataFromCta } from "@utils/routing"

const CTA = ({ id, cta, className, buttonClassName, arrowFill, tabIndex }) => {
  const { text: linkText, url, mode } = getLinkDataFromCta(cta);
  const minWidth = cta?.minWidth ? { minWidth: cta.minWidth, justifyContent: 'center' } : {};

  return (
    <>
      {mode == 'internal' &&
        <div id={id} className={`${className ?? "mt-4 mx-auto text-center sm:text-left"}`}>
          <Button ariaLabel={linkText} className={`${buttonClassName} px-8 py-3`} style={minWidth} internalLink={url} fill={arrowFill} tabIndex={tabIndex}>
            {linkText}
          </Button>
        </div>}
      {mode == 'external' &&
        <div id={id} className={`${className ?? "mt-4 mx-auto text-center sm:text-left"}`}>
          <Button ariaLabel={linkText} className={`px-8 py-3 ${buttonClassName}`} style={minWidth} externalLink={url} fill={arrowFill} tabIndex={tabIndex}>
            {linkText}
          </Button>
        </div>}
    </>
  )
}

export default CTA
