export const subscribe = (listId, email, params = {}) => {
  const $fields = [
    'Pups Name',
    'Pups Birthday',
    'Pups Breed',
  ].join(',')
  const config = Object.assign({}, params, {
    g: listId,
    email,
    $fields,
  })

  const body = Object.keys(config).reduce((str, key) => {
    str.append(key, config[key])
    return str
  }, new URLSearchParams())

  return fetch("https://manage.kmail-lists.com/ajax/subscriptions/subscribe", {
    method: "POST",
    headers: {
      "Access-Control-Allow-Headers": "*",
      "Content-Type": "application/x-www-form-urlencoded; charset=utf-8",
    },
    body,
  }).then(res => res.json())
};

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const fieldWidth = {
  'full': 'input-full',
  'half': 'input-half',
  'one-third': 'input-one-third'
}

export const addClassToElements = (elements, className, indexToMatch) => {
  elements?.forEach((element, index) => {
    if ((index + 1) % indexToMatch == 0 && element) {
      element.classList.add(className);
    }
  })
}
