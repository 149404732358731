const { createInnerProjection, getRegion } = require("./helpers")

const productColors = `*[_type == "productColor"] {
  _id,
  colorName,
  color {
    hex    
  }
}`

const cashbackModal = `*[_type == 'cashbackModal'] {
  ...
}`

const asset =
`asset -> {
  _id,
  originalFilename,
  "filename": originalFilename,
  title,
  altText,
}`

const instaPosts = `*[_type == 'instaPost'][0...10] | order(timestamp desc) {
  alternativeCaption,
  timestamp,
  _updatedAt,
  permalink,
  caption,
  post {
    _type,
    ${asset}
  },
  productReference-> {
    _id,
    color,
    variantColor[]-> {
      ...
    },
    shopifyId,
    productId,
    listingVariantTitle
  }
}`

const video = 
`_type == "video" => {
  _key,
  _type,
  placeholderImage {
    _type,
    ${asset}
  },
  defaultSettings,
  video {
    _type,
    asset {
      _id,
      originalFilename,
      title,
      altText,
      url,
      mimeType
    }
  },
  vimeoVideoId,
  url,
  muted,
  loop,
  controls,
  autoplay,
  aspectRatio,
  title
}`;

const klaviyoSettings = `*[_type == "klaviyo"][0] {
  unreleasedProducts[]-> {
    shopifyId,
    variantTitle,
  },
  backInStockButtonText,
  unreleasedProductButtonText,
}`

const mainImage = 
`mainImage {
  ${asset},
  crop {
    top,
    bottom,
    left,
    right,
    _key,
    _type,
  },
  hotspot {
    _key,
    _type,
    height,
    width,
  },
  _key,
  _type,
}`

const image =
`image {
  ${asset},
  _key,
  _type,
}`

const listingImage = `
listingImage {
  ${asset},
  _key,
  _type,
}`

const internalLink = `
  internalLink -> {
    _type == 'blogPost' => {
      id,
      _key,
      _type,
      slug {
        current,
        _type,
      },
      categories[] {
        _type,
        _id,
        id,
        slug {
          current,
          _type,
        },
      },
    },
    _type == 'category' => {
      id,
      _key,
      _type,
      slug {
        current,
        _type,
      },
    },
    _type == 'landingPage' => {
      id,
      _key,
      _type,
      slug {
        current,
        _type,
      },
    },
    _type == 'resourcePost' => {
      id,
      _key,
      _type,
      slug {
        current,
        _type,
      },
    },
    _type == 'shopifyProduct' => {
      id,
      _key,
      _type,
      slug {
        current,
        _type,
      },
      productGroups[] -> {
        id,
        slug {
          current,
          _type,
        },
        title,
        _id,
        _type,
      },
    },
    _type == 'simpleLandingPage' => {
      id,
      _key,
      _type,
      slug {
        current,
        _type,
      },
      productGroups[] -> {
        id,
        _key,
        _type,
        title,
        slug {
          current,
          _type,
        },
      },
    },
  }
`

const cta = 
`arrow,
ctaWidth {
  desktopWidth,
  mobileWidth,
  _type,
},
externalLink,
${internalLink},
nofollow,
openInNewWindow,
text,
theme {
  desktop,
  mobile,
  _type,
},
themeHover {
  desktop,
  mobile,
  _type,
},
type,
_key,
_type`;

const ctaList = `
  ctaList[] {
    ${cta}
  }
`

const variantImages =
`variantImages[] {
  _type == 'image' => {
    _type,
    ${asset}
  },
  _type == 'shopifyImage' => {
    _type,
    _key,
    shopifyURL,
    description
  },
  ${video}
}
`

const openGraph =
`openGraph {
  title,
  description,
  ${image}
}`

const youMightAlsoLike = 
`title,
shortTitle,
subtitle,
slug {
  current,
},
_id,
_type,
enabled,
showInProductListing,
summary,
shopifyId,
yotpoProductId,
priceFrom,
priceTo,
productOptions,
"isAvailableInRegion": select(
  count(visibilityRegions[]) == 0 || visibilityRegions[] == null || "${getRegion()}" in visibilityRegions[] => true,
  false
),
variants[] -> {
  ...,
  "isAvailableInRegion": select(
    count(visibilityRegions[]) == 0 || visibilityRegions[] == null || "${getRegion()}" in visibilityRegions[] => true,
    false
  ),
  selectedOptions[] {
    name,
    value
  },
  variantComponents[]-> {
    shopifyIdBase64Encoded,
    price,
    compareAtPrice,
    sku,
    variantTitle,
    status {
      backOrderDetailsClassName,
      backorder,
      klaviyoTriggerId,
      preorder,
      watchlist,
      watchlistFormTriggerClassName,
      watchlistDescription[] {
        ...
      }
    },
    ${listingImage}
  },
  hideOnBundle[] -> {
    _id
  },
  hideVariantOnGroup[] -> {
    slug {
      current
    }
  },
  ${listingImage},
  ${variantImages}
},
${mainImage},
productGroups[] -> {
  title,
  _type,
  slug {
    current,
  },
},
visibilityRegions`

const crossSell = 
`crossSell[] -> {
  freeShippingLabel,
  _type,
  title,
  mainImage,
  "isAvailableInRegion": select(
    count(visibilityRegions[]) == 0 || visibilityRegions[] == null || "${getRegion()}" in visibilityRegions[] => true,
    false
  ),
  productOptions[] {
    ...
  },
  priceFrom,
  priceTo,
  shopifyId,
  yotpoProductId,
  slug {
    current
  },
  productGroups[]-> {
    title,
    _type,
    slug {
      current
    }
  },
  productDescription[] {
    ...
  },
  visibilityRegions,
  summary,
  shippingTimeEstimate,
  ...select(
    count(youMightAlsoLike[]) > 0 => {
      youMightAlsoLike[]->{
        ${youMightAlsoLike}
      },
    },
    count(youMightAlsoLike[]) == 0 => {
      "youMightAlsoLike": *[_type == "shopifyProduct" && enabled == true && isBundle != true][0...3] | order(orderRank asc) {
        ${youMightAlsoLike}
      },
    }
  ),
  variants[] -> {
    availableForSale,
    "isAvailableInRegion": select(
      count(visibilityRegions[]) == 0 || visibilityRegions[] == null || "${getRegion()}" in visibilityRegions[] => true,
      false
    ),
    shopifyIdBase64Encoded,
    shopifyId,
    shippingTimeEstimate,
    shippingTimeEstimateCA,
    visibilityRegions,
    isDefaultVariant,
    isAvailableWithPrime,
    price,
    sku,
    variantTitle,
    compareAtPrice,
    selectedOptions[] {
      name,
      value
    },
    hideOnBundle[] -> {
      _id
    },
    hideVariantOnGroup[] -> {
      slug {
        current
      }
    },
    status {
      preorder,
      backorder,
      watchlist,
      klaviyoTriggerId,
    },
    prices[] {
      price,
      compareAtPrice,
      currency,
    },
    ${variantImages},
    variantComponents[]-> {
      shopifyIdBase64Encoded,
      price,
      compareAtPrice,
      sku,
      variantTitle,
      status {
        backOrderDetailsClassName,
        backorder,
        klaviyoTriggerId,
        preorder,
        watchlist,
        watchlistFormTriggerClassName,
        watchlistDescription[] {
          ...
        }
      },
      ${listingImage}
    },
  },
}`

const siteSettings =`
  _id,
  ${openGraph},
  announcementBar {
    slides[] {
      mainText,
      visibilityRegions,
      checkUTMParams,
      dontShowIn,
      showIn,
      otherPages,
      cta {
        ${cta},
      },
      modal {
        show,
        title,
        icon {
          asset -> {
            url,
          },
        },
        richText,
      }
    }
  },
  myRegistryInfo,
  "productColors": ${productColors},
  freeShippingLabel,
  "cashbackModal": ${cashbackModal},
  "instaPosts": ${instaPosts},
  "klaviyo": ${klaviyoSettings},
  "cartLabels": *[_type == "cart"]{
    backorderLabel,
    defaultLabel,
    preorderLabel,
    watchlistLabel
  }[0]
`

// const centeredCopy = 
// `centeredCopy {
//     ...,
//     ${ctaList},
//   }
// `

// const copy = 
// `copy -> {
//     _key,
//     _type,
//     "copyText": _rawText,
//     visibilityRegions,
//   }
// `

// const hero =
// `hero[] {
//   ...,
//   slides[] {
//     ...,
//   }
// }`

const richText =
`...,
_type == "richTextImage" => {
  ...,
  ${asset}
}`

const imageLabels = 
`_type == "imageLabels" => {
  imageLabelPosition,
  _type,
  labelList[] {
    _type,
    _key,
    linkType,
    label,
    isProductReference,
    externalLink,
    internalLink -> {
      id,
      _key,
      _type,
      slug {
        current
      },
      _type == 'shopifyProduct' => {
        productGroups[]-> {
          _type,
          _id,
          id,
          slug {
            current
          },
          title
        },
      },
      _type == 'simpleLandingPage' => {
        title,
      }
    },
    variantReference -> {
      _id,
      color,
      variantColor[]-> {
        ...
      },
      shopifyId,
    },
    productReference -> {
      _type,
      _id,
      id,
      title,
      slug {
        current
      },
      productGroups[]-> {
        title,
        slug {
          current
        },
        _type
      },
      isBundle,
      shopifyId
    }
  }
}`;

const customImage = 
`_key,
_type,
heightMobile,
heightDesktop,
desktop {
  ...,
  image [] {
    ...,
    ${asset}
  }
},
mobile {
  ...,
  image [] {
    ...,
    ${asset}
  }
},
labels {
  ${imageLabels}
},
externalUrl,
${internalLink}`

const contentImage = 
`columnWidth,
textPlacement,
text,
image {
  _key,
  _type,
  asset -> {
    _id,
    originalFilename,
    title,
    altText,
    filename
  }
}`

const heroSlider = 
`_type == "heroSlider" => {
  _key,
  _type,
  title,
  defaultSettings,
  sliderSettings,
  slides[] {
    bgImage {
      ${customImage}
    },
    _key,
    heading,
    _type,
    visibilityRegions,
    ${ctaList}
  }
}`

const servicesPoliciesInfo = 
`_type == "servicesPoliciesInfo" => {
  _type,
  _key,
  items[] {
    icon {
      svg
    },
    text
  }
}`

const newsletterBanner = 
`_type == "newsletterBanner" => {
  heading,
  subheading,
  inputs[] {
    label,
    name,
    placeholder,
    type,
    required,
    width,
    selectionElements[] {
      isDefaultValue,
      text
    }
  },
  defaultSettings
}`;

const imageBlock = 
`_type == "imageBlock" => {
  _type,
  _key,
  title,
  image {
    ${customImage}
  },
  defaultSettings
}`;

const contentImages = 
`_type == "contentImages" => {
  _key,
  _type,
  id,
  sizeFilter,
  images[] {
    ${createInnerProjection(['customImage'], customImage)},
    ${createInnerProjection(['contentImage'], contentImage)}
  }
}`;

const contentGrid = 
`_type == "contentGrid" => {
  _key,
  _type,
  id,
  title,
  "gridItems": items[] {
    ${createInnerProjection(['contentImage'], contentImage)}
  }
}`;

const centeredCopy = 
`_type == "centeredCopy" => {
  _key,
  _type,
  "copyText": text[] {
    ...
  },
  defaultSettings,
  ${ctaList}
}`;

const pressSlider = 
`_type == "pressSlider" => {
  _key,
  _type,
  "pressSlides": slides[] {
    _type,
    _key,
    content,
    author,
    image {
      _type,
      ${asset}
    }
  },
  heading,
  subheading,
  sliderSettings,
  defaultSettings
}`;

const embeddedHTML = 
`_type == "embeddedHTML" => {
  id,
  minHeight,
  scripts,
  title,
  html,
  _type,
  _key,
  defaultSettings
}`;

const richTextObject = 
`_type == "richTextObject" => {
  id,
  content[] {
    ${richText}
  },
  _key,
  _type
}`;

const pdf = 
`_type == "pdf" => {
  _key,
  _type,
  width,
  height,
  pdf {
    _type,
    asset -> {
      _id,
      title,
      url
    }
  },
  title,
  url,
  defaultSettings
}`;

const reviewsWidget =
`_type == "reviewsWidget" => {
  _key,
  _type,
  type,
  reviewsTitle,
  "reviewsDescription": description[] {
    ${richText}
  },
  product -> {
    uri,
    title,
    yotpoProductId
  },
  "items": products[]-> {
    id,
    title,
    yotpoProductId,
    uri
  }
}`;

const sectionBuilder = 
`_type == "sectionBuilder" => {
  _key,
  _type,
  defaultSettings,
  title,
  columns[] {
    _key,
    _type,
    columnSettings {
      ...
    },
    bottomContent[] {
      ${reviewsWidget},
      _type == "cta" => {
        ${cta}
      },
      ${embeddedHTML},
      ${richTextObject},
      ${video},
      _type == "customImage" => {
        ${customImage}
      }
    },
    topContent[] {
      ${reviewsWidget},
      _type == "cta" => {
        ${cta}
      },
      ${embeddedHTML},
      ${richTextObject},
      ${video},
      _type == "customImage" => {
        ${customImage}
      }
    }
  }
}`;

const collectionList = 
`_type == "collectionList" => {
  _key,
  _type,
  defaultImage {
    _type,
    ${asset}
  },
  defaultSettings,
  heading,
  ${ctaList},
  collections[]-> {
    _type,
    title,
    slug {
      current
    },
    collectionImage {
      _type,
      ${asset}
    },
    mainImage {
      _type,
      ${asset}
    },
    mobileCoverImage {
      _type,
      ${asset}
    }
  }
}`;

const bundleShowcase = 
`_type == "bundleShowcase" => {
  _key,
  _type,
  sliderSettings,
  defaultSettings,
  heading,
  description,
  ${ctaList},
  bundle -> {
    title,
    bundleProductsList[] {
      ...product-> {
        shopifyId,
        shortTitle,
      }
    }
  },
  bundleVariants[]-> {
    color,
    listingVariantTitle,
    productId,
    variantColor[] -> {
      ...
    },
    listingImage {
      _key,
      _type,
      ${asset}
    },
    ${variantImages}
  }
}`;

const blogSlider = 
`_type == "blogSlider" => {
  _key,
  _type,
  type,
  summary,
  heading,
  description,
  sliderSettings,
  defaultSettings,
  blogList[]-> {
    id,
    _type,
    title,
    slug {
      current
    },
    mainImage {
      _key,
      _type,
      ${asset}
    },
    categories[]-> {
      slug {
        current
      }
    }
  }
}`;

const instaFeed = 
`_type == "instaFeed" => {
  _id,
  _key,
  _type,
  type,
  heading,
  description,
  sliderSettings,
  defaultSettings,
  type == "latest" => { 
    "instaPosts": ${instaPosts}
  },
  type == "custom" => {
    instaPosts[]-> {
      alternativeCaption,
      timestamp,
      _updatedAt,
      permalink,
      caption,
      post {
        _type,
        ${asset}
      },
      productReference-> {
        _id,
        color,
        variantColor[]-> {
          ...
        },
        shopifyId,
        productId,
        listingVariantTitle
      }
    }
  }
}`;

const infoColumns = 
`_type == "infoColumns" => {
  _key,
  _type,
  type,
  defaultSettings,
  showPopup,
  title,
  type == "latest" => {
    "items": *[_type == 'infoSection'] | order(orderRank) {
      image {
        _key,
        _type,
        ${asset}
      },
      title,
      subTitle,
      _id,
      _key,
      description[] {
        ...
      },
      ${ctaList}
    }
  },
  type == "custom" => {
    items[] {
      image {
        _key,
        _type,
        ${asset}
      },
      title,
      subTitle,
      _id,
      _key,
      description[] {
        ...
      },
      ${ctaList}
    }
  }
}`;

const comparisonTable = 
`_type == "comparisonTable" => {
  _key,
  _type,
  hideTableLegend,
  hideDescriptionsOnMobile,
  borderRadius,
  title,
  tableGroup[] {
    _key,
    _type,
    title,
    comparisonLine[] {
      _key,
      _type,
      title,
      description,
      items
    }
  },
  itemsForComparison[] {
    _key,
    _type,
    title,
    image {
      _type,
      ${asset}
    }
  },
  defaultSettings
}`;

const quote = 
`_type == "quote" => {
  _key,
  _type,
  id,
  cta {
    ${cta}
  },
  credit,
  subtitle,
  includeQuotationMarks,
  "quoteText": text
}`;

const bestSellersProduct = 
`title,
shortTitle,
subtitle,
slug {
  current
},
_id,
_type,
enabled,
showInProductListing,
summary,
shopifyId,
yotpoProductId,
priceFrom,
priceTo,
productOptions,
variants[]-> {
  ...,
  "isAvailableInRegion": select(
    count(visibilityRegions[]) == 0 || visibilityRegions[] == null || "${getRegion()}" in visibilityRegions[] => true,
    false
  ),
  selectedOptions[] {
    name,
    value
  },
  listingImage {
    _type,
    ${asset}
  },
  ${variantImages},
  variantColor[]-> {
    ...
  },
  hideOnBundle[] -> {
    _id
  },
  hideVariantOnGroup[] -> {
    slug {
      current
    }
  },
  variantComponents[]-> {
    shopifyIdBase64Encoded,
    price,
    compareAtPrice,
    sku,
    variantTitle,
    status {
      backOrderDetailsClassName,
      backorder,
      klaviyoTriggerId,
      preorder,
      watchlist,
      watchlistFormTriggerClassName,
      watchlistDescription[] {
        ...
      }
    },
    ${listingImage}
  },
},
mainImage {
  ${asset}
},
productGroups[]-> {
  title,
  _type,
  slug {
    current
  }
},
visibilityRegions,
productOptions[] {
  ...
}`

const bestSellers = 
`_type == "bestSellers" => {
  _key,
  _type,
  id,
  title,
  sectionIntro,
  products[]->{
    ${bestSellersProduct}
  }
}`;

const carousel = 
`_type == "carousel" => {
  _key,
  _type,
  sliderSettings,
  defaultSettings,
  heading,
  subheading,
  "carouselSlides": slides[] {
    ...,
    image {
      _type,
      ${asset}
    }
  }
}`;

const contactForm = 
`_type == "contactForm" => {
  _key,
  _type,
  id,
  title
}`;

const copy = 
`_type == "copy" => {
  _key,
  _type,
  "copyText": text[] {
    ${richText}
  },
  visibilityRegions
}`;

const greenhouseList = 
`_type == "greenhouseList" => {
  _key,
  _type,
  subtitle,
  title
}`;

const helpCenter = 
`_type == "helpCenter" => {
  _key,
  _type,
  defaultSettings,
  heading,
  description,
  backgroundImage {
    _type,
    ${asset}
  }
}`;

const heroFragment = 
`_type == "hero" => {
  _key,
  _type,
  heading,
  bgImage {
    ${customImage}
  },
  ${ctaList}
}`;

const logoList = 
`_type == "logoList" => {
  _type,
  _key,
  id,
  showLogoList,
  title
}`;

const mainHero = 
`_type == "mainHero" => {
  _type,
  _key,
  id,
  ctaLinkText,
  ctaLinkUrl,
  pullUpImageOnMobile,
  reverse,
  title,
  vimeoVideoId,
  "MainHeroText": text,
  cta {
    ${cta}
  },
  secondaryCta {
    ${cta}
  },
  "MainHeroImage": image {
    _type,
    ${asset}
  },
  logos[] {
    _key,
    _type,
    image {
      _key,
      _type,
      ${asset}
    },
    url
  }
}`;

const perkList = 
`_type == "perkList" => {
  _type,
  _key,
  id,
  title,
  perks[] {
    title,
    _key,
    icon {
      image {
        _key,
        _type,
        ${asset}
      },
      columnWidth,
      text,
      textPlacement
    }
  }
}`;

const supportPoints = 
`_type == "supportPoints" => {
  _type,
  _key,
  id,
  title
}`;

const testimonials = 
`_type == "testimonials" => {
  _type,
  _key,
  id,
  title,
  quotes[] {
    _type,
    _key,
    credit,
    id,
    includeQuotationMarks,
    subtitle,
    text,
    cta {
      ${cta}
    },
    image {
      image {
        _key,
        _type,
        ${asset}
      },
      text
    }
  }
}`;

const valueList = 
`_type == "valueList" => {
  _type,
  _key,
  id,
  title,
  "valueImage": image {
    _type,
    ${asset}
  },
  values[] {
    _type == "cta" => {
      ${cta}
    },
    _type == "value" => {
      _key,
      _type,
      description,
      title
    }
  }
}`;

const wuufooForm = 
`_type == "wuufooForm" => {
  _type,
  _key,
  id,
  formHash
}`;

const referAFriend = 
`_type == "referAFriend" => {
  _key,
  _type,
  defaultSettings
}`;

const accordionSection = 
`_type == "accordionSection" => {
  _key,
  _type,
  title,
  "accordionSectionDescription": description,
  accordionList[] {
    _key,
    _type,
    title,
    description,
    orderRank
  },
  defaultSettings
}`;

const productSliderItems = `
_id,
_type,
id,
enabled,
title,
shortTitle,
subtitle,
shopifyId,
yotpoProductId,
summary,
extraDiscountMessage,
discountAmount,
discountDisplayPercentage,
shippingTimeEstimate,
slug {
  current
},
${mainImage},
productGroups[]-> {
  title,
  _type,
  slug {
    current
  }
},
visibilityRegions,
cardFlag {
    _type,
    label,
    colorSettings,
},
isBundle,
isBundle == true => {
  bundleImages[] {
    _key,
    _type,
    _type == "bundleImage" => {
      image {
        _type,
        ${asset}
      },
      description
    },
    _type == "shopifyImage" => {
      shopifyURL,
      description
    },
    ${video}
  },
  bundleProductsList[] {
    hasCustomVariants,
    discountType,
    discountAmount,
    discountQuantity,
    isOptional,
    ...select(
      hasCustomVariants == true => {
        ...product-> {
          _type,
          _id,
          slug {
            current
          },
          productGroups[]-> {
            title,
            _type,
            slug {
              current
            }
          },
          productOptions[] {
            ...
          },
          title,
          shopifyId,
          yotpoProductId,
          visibilityRegions,
          summary,
        },
        "variants": variants[]->{
          availableForSale,
          "isAvailableInRegion": select(
            count(visibilityRegions[]) == 0 || visibilityRegions[] == null || "${getRegion()}" in visibilityRegions[] => true,
            false
          ),
          sku,
          _id,
          shopifyId,
          shopifyIdBase64Encoded,
          price,
          compareAtPrice,
          extraDiscountMessage,
          hide,
          visibilityRegions,
          selectedOptions[] {
            name,
            value
          },
          hideOnBundle[] -> {
            _id
          },
          hideVariantOnGroup[] -> {
            slug {
              current
            }
          },
          prices[] {
            price,
            compareAtPrice,
            currency,
          },
          ${variantImages},
          variantComponents[]-> {
            shopifyIdBase64Encoded,
            price,
            compareAtPrice,
            sku,
            variantTitle,
            prices[] {
              price,
              compareAtPrice,
              currency,
            },
            status {
              backOrderDetailsClassName,
              backorder,
              klaviyoTriggerId,
              preorder,
              watchlist,
              watchlistFormTriggerClassName,
              watchlistDescription[] {
                ...
              }
            },
            ${listingImage}
          }
        }
      },
      hasCustomVariants != true => {
        ...product-> {
          _type,
          _id,
          slug {
            current
          },
          productGroups[]-> {
            title,
            _type,
            slug {
              current
            }
          },
          productOptions[] {
            ...
          },
          title,
          shopifyId,
          yotpoProductId,
          visibilityRegions,
          summary,
          variants[]-> {
            availableForSale,
            "isAvailableInRegion": select(
              count(visibilityRegions[]) == 0 || visibilityRegions[] == null || "${getRegion()}" in visibilityRegions[] => true,
              false
            ),
            sku,
            _id,
            shopifyId,
            shopifyIdBase64Encoded,
            price,
            extraDiscountMessage,
            hide,
            visibilityRegions,
            price,
            compareAtPrice,
            hideOnBundle[] -> {
              _id
            },
            selectedOptions[] {
              name,
              value
            },
            hideVariantOnGroup[] -> {
              slug {
                current
              }
            },
            prices[] {
              price,
              compareAtPrice,
              currency,
            },
            ${variantImages},
            variantComponents[]-> {
              shopifyIdBase64Encoded,
              price,
              compareAtPrice,
              prices[] {
                price,
                compareAtPrice,
                currency,
              },
              sku,
              variantTitle,
              status {
                backOrderDetailsClassName,
                backorder,
                klaviyoTriggerId,
                preorder,
                watchlist,
                watchlistFormTriggerClassName,
                watchlistDescription[] {
                  ...
                }
              },
              ${listingImage}
            }
          }
        },
      },
    )
  }
},
productOptions[] {
  ...
},
variants[]-> {
  availableForSale,
  "isAvailableInRegion": select(
    count(visibilityRegions[]) == 0 || visibilityRegions[] == null || "${getRegion()}" in visibilityRegions[] => true,
    false
  ),
  _id,
  sku,
  shopifyId,
  shopifyIdBase64Encoded,
  variantTitle,
  listingVariantTitle,
  extraDiscountMessage,
  selectedOptions[] {
    name,
    value
  },
  price,
  compareAtPrice,
  prices[] {
    price,
    compareAtPrice,
    currency,
  },
  size,
  color,
  flavor,
  configuration,
  isDefaultVariant,
  isAvailableWithPrime,
  hideOnBundle[] -> {
    _id
  },
  hideVariantOnGroup[] -> {
    slug {
      current
    }
  },
  variantComponents[]-> {
    shopifyIdBase64Encoded,
    price,
    compareAtPrice,
    prices[] {
      price,
      compareAtPrice,
      currency,
    },
    sku,
    variantTitle,
    status {
      backOrderDetailsClassName,
      backorder,
      klaviyoTriggerId,
      preorder,
      watchlist,
      watchlistFormTriggerClassName,
      watchlistDescription
    },
    listingImage {
      _type,
      ${asset}
    }
  },
  listingImage {
    _type,
    ${asset}
  },
  ${variantImages},
  status {
    backorder,
    preorder,
    watchlist,
    backOrderDetailsClassName,
    watchlistFormTriggerClassName,
    klaviyoTriggerId
  },
  hide,
  shippingTimeEstimate,
  shippingTimeEstimateCA,
  visibilityRegions,
  variantColor[]-> {
    colorName,
    color {
      hex
    }
  }
},
`

const productSlider = 
`_type == "productSlider" => {
  _key,
  _type,
  sliderSettings,
  defaultSettings,
  type,
  heading,
  description,
  cardFlags,
  atcLabel,
  productInfos,
  productGroupItems[] {
    _type,
    hasFeaturedVariants,
    product->{
      ${productSliderItems}
    },
    featuredVariants[]->{
      _id,
      sku,
      shopifyId,
      shopifyIdBase64Encoded,
      availableForSale,
      "isAvailableInRegion": select(
        count(visibilityRegions[]) == 0 || visibilityRegions[] == null || "${getRegion()}" in visibilityRegions[] => true,
        false
      ),
      selectedOptions[] {
        name,
        value
      },
      variantTitle,
      listingVariantTitle,
      extraDiscountMessage,
      price,
      size,
      color,
      flavor,
      configuration,
      isDefaultVariant,
      isAvailableWithPrime,
      hideOnBundle[] -> {
        _id
      },
      hideVariantOnGroup[] -> {
        slug {
          current
        }
      },
      variantComponents[]-> {
        shopifyIdBase64Encoded,
        price,
        compareAtPrice,
        sku,
        variantTitle,
        status {
          backOrderDetailsClassName,
          backorder,
          klaviyoTriggerId,
          preorder,
          watchlist,
          watchlistFormTriggerClassName,
          watchlistDescription
        },
        listingImage {
          _type,
          ${asset}
        }
      },
      listingImage {
        _type,
        ${asset}
      },
      ${variantImages},
      status {
        backorder,
        preorder,
        watchlist,
        backOrderDetailsClassName,
        watchlistFormTriggerClassName,
        klaviyoTriggerId
      },
      hide,
      shippingTimeEstimate,
      shippingTimeEstimateCA,
      visibilityRegions,
      variantColor[]-> {
        colorName,
        color {
          hex
        }
      }
    },
  }
}`;

const contentBuilder =
`contentBuilder[] {
  ${heroSlider},
  ${productSlider},
  ${servicesPoliciesInfo},
  ${centeredCopy},
  ${pressSlider},
  ${collectionList},
  ${sectionBuilder},
  ${imageBlock},
  ${bundleShowcase},
  ${blogSlider},
  ${instaFeed},
  ${infoColumns},
  ${contentImages},
  ${contentGrid},
  ${greenhouseList},
  ${helpCenter},
  ${reviewsWidget},
  ${accordionSection},
  ${pdf},
  ${video},
  ${quote},
  ${bestSellers},
  ${carousel},
  ${contactForm},
  ${copy},
  ${wuufooForm},
  ${newsletterBanner},
  ${comparisonTable},
  ${embeddedHTML},
  ${richTextObject},
  ${cta},
  ${referAFriend},
  ${valueList},
  ${heroFragment},
  ${logoList},
  ${mainHero},
  ${perkList},
  ${supportPoints},
  ${testimonials}
}`

const productGroups = 
`_id,
  title,
  _type,
  slug {
    current
  },
  description,
  visibilityRegions,
  hiddenVariants[] -> {
    shopifyId
  },
  ${mainImage},
  mobileCoverImage {
    _type,
    ${asset}
  },
  subcategories[] -> {
    hide,
    slug {
      current,
      _type,
    },
    title,
    visibilityRegions,
    _id,
  },
  ${contentBuilder}
`

const productCard = `
  title,
  slug {
    current,
  },
  discountAmount,
  _id,
  _type,
  isBundle,
  enabled,
  shopifyId,
  yotpoProductId,
  shortTitle,
  showInProductListing,
  visibilityRegions,
  summary,
  subtitle,
  priceFrom,
  bundleImages[] {
    _key,
    _type,
    ${image},
    description,
  },
  bundleProductsList[] {
    hasCustomVariants,
    discountType,
    discountAmount,
    discountQuantity,
    isOptional,
    ...select(
      hasCustomVariants == true => {
        ...product-> {
          _type,
          _id,
          title,
          slug {
            current
          },
          isBundle,
          shopifyId,
          yotpoProductId,
          visibilityRegions,
          summary,
          productGroups[] -> {
            _id,
            title,
            _type,
            slug {
              current,
            },
            visibilityRegions
          },
          productOptions[] {
            ...
          },
          ${mainImage},
        },
        "variants": variants[]->{
          variantTitle,
          shopifyId,
          shopifyIdBase64Encoded,
          isDefaultVariant,
          isAvailableWithPrime,
          "isAvailableInRegion": select(
            count(visibilityRegions[]) == 0 || visibilityRegions[] == null || "${getRegion()}" in visibilityRegions[] => true,
            false
          ),
          sku,
          availableForSale,
          price,
          compareAtPrice,
          hideOnBundle[] -> {
            _id
          },
          selectedOptions[] {
            name,
            value
          },
          hideVariantOnGroup[] -> {
            slug {
              current
            }
          },
          status {
            backorder,
            preorder,
            watchlist,
            backOrderDetailsClassName,
            watchlistFormTriggerClassName,
            watchlistDescription,
            klaviyoTriggerId,
          },
          prices[] {
            price,
            compareAtPrice,
            currency,
          },
          variantComponents[]-> {
            shopifyIdBase64Encoded,
            price,
            compareAtPrice,
            prices[] {
              price,
              compareAtPrice,
              currency,
            },
            sku,
            variantTitle,
            status {
              backOrderDetailsClassName,
              backorder,
              klaviyoTriggerId,
              preorder,
              watchlist,
              watchlistFormTriggerClassName,
              watchlistDescription[] {
                ...
              }
            },
            ${listingImage}
          },
          hide,
          visibilityRegions,
          ${variantImages}
        }
      },
      hasCustomVariants != true => {
        ...product-> {
          _type,
          _id,
          title,
          slug {
            current
          },
          isBundle,
          shopifyId,
          yotpoProductId,
          visibilityRegions,
          summary,
          productGroups[] -> {
            _id,
            title,
            _type,
            slug {
              current,
            },
            visibilityRegions
          },
          productOptions[] {
            ...
          },
          ${mainImage},
          variants[]-> {
            variantTitle,
            shopifyId,
            shopifyIdBase64Encoded,
            isDefaultVariant,
            isAvailableWithPrime,
            sku,
            availableForSale,
            "isAvailableInRegion": select(
              count(visibilityRegions[]) == 0 || visibilityRegions[] == null || "${getRegion()}" in visibilityRegions[] => true,
              false
            ),
            price,
            compareAtPrice,
            prices[] {
              price,
              compareAtPrice,
              currency,
            },
            hideOnBundle[] -> {
              _id
            },
            selectedOptions[] {
              name,
              value
            },
            hideVariantOnGroup[] -> {
              slug {
                current
              }
            },
            status {
              backorder,
              preorder,
              watchlist,
              backOrderDetailsClassName,
              watchlistFormTriggerClassName,
              watchlistDescription,
              klaviyoTriggerId,
            },
            variantComponents[]-> {
              shopifyIdBase64Encoded,
              price,
              compareAtPrice,
              prices[] {
                price,
                compareAtPrice,
                currency,
              },
              sku,
              variantTitle,
              status {
                backOrderDetailsClassName,
                backorder,
                klaviyoTriggerId,
                preorder,
                watchlist,
                watchlistFormTriggerClassName,
                watchlistDescription[] {
                  ...
                }
              },
              ${listingImage}
            },
            hide,
            visibilityRegions,
            ${variantImages}
          }
        },
      },
    )
  },
  variants[] -> {
    _id,
    variantTitle,
    listingVariantTitle,
    sku,
    price,
    compareAtPrice,
    size,
    color,
    prices[] {
      price,
      compareAtPrice,
      currency,
    },
    selectedOptions[] {
      name,
      value
    },
    flavor,
    configuration,
    shopifyId,
    shopifyIdBase64Encoded,
    isDefaultVariant,
    isAvailableWithPrime,
    "isAvailableInRegion": select(
      count(visibilityRegions[]) == 0 || visibilityRegions[] == null || "${getRegion()}" in visibilityRegions[] => true,
      false
    ),
    availableForSale,
    hideOnBundle[] -> {
      _id
    },
    hideVariantOnGroup[] -> {
      slug {
        current
      }
    },
    variantComponents[] -> {
      shopifyIdBase64Encoded,
      price,
      compareAtPrice,
      prices[] {
        price,
        compareAtPrice,
        currency,
      },
      sku,
      variantTitle,
      status {
        backOrderDetailsClassName,
        backorder,
        klaviyoTriggerId,
        preorder,
        watchlist,
        watchlistFormTriggerClassName,
        watchlistDescription,
      },
      ${listingImage}
    },
    ${listingImage},
    ${variantImages},
    status {
      backorder,
      preorder,
      watchlist,
      backOrderDetailsClassName,
      watchlistFormTriggerClassName,
      watchlistDescription,
      klaviyoTriggerId,
    },
    hide,
    shippingTimeEstimate,
    visibilityRegions,
    variantColor[]-> {
      ...
    }
  },
  ${mainImage},
  productGroups[] -> {
    _id,
    title,
    _type,
    slug {
      current,
    },
    visibilityRegions,
  },
  productOptions[] {
    _type,
    _key,
    optionName,
    optionValues[] {
      _key,
      originalValueName,
      customDisplayedValueName,
    },
    optionType,
  },
  bundleColorsHex,
  cardFlag {
    _type,
    label,
    colorSettings,
  },
  discountDisplayPercentage
`

const bundleProduct = `
hasCustomVariants,
discountType,
discountAmount,
discountQuantity,
isOptional,
...select(
  hasCustomVariants == true => {
    ...product-> {
      isBundle,
      ${mainImage},
      productGroups[] -> {
        ${productGroups},
      },
      productOptions[] {
        ...
      },
      shopifyId,
      title,
      productImages[] {
        ${image},
      },
      visibilityRegions,
      yotpoProductId,
      _type,
      _id,
      discountCode,
      priceFrom,
      slug {
        current
      },
      productDescription,
      summary
    },
    "variants": variants[]->{
      ...,
      "isAvailableInRegion": select(
        count(visibilityRegions[]) == 0 || visibilityRegions[] == null || "${getRegion()}" in visibilityRegions[] => true,
        false
      ),
      selectedOptions[] {
        name,
        value
      },
      hideOnBundle[] -> {
        _id
      },
      hideVariantOnGroup[] -> {
        slug {
          current
        }
      },
      prices[] {
        price,
        compareAtPrice,
        currency,
      },
      variantComponents[]-> {
        shopifyIdBase64Encoded,
        price,
        compareAtPrice,
        prices[] {
          price,
          compareAtPrice,
          currency,
        },
        sku,
        variantTitle,
        status {
          backOrderDetailsClassName,
          backorder,
          klaviyoTriggerId,
          preorder,
          watchlist,
          watchlistFormTriggerClassName,
          watchlistDescription[] {
            ...
          }
        },
        ${listingImage}
      },
      ${listingImage},
      ${variantImages}
    }
  },
  hasCustomVariants != true => {
    ...product-> {
      isBundle,
      ${mainImage},
      productGroups[] -> {
        ${productGroups},
      },
      shopifyId,
      title,
      productImages[] {
        ${image},
      },
      productOptions[] {
        ...
      },
      visibilityRegions,
      yotpoProductId,
      summary,
      _type,
      _id,
      discountCode,
      priceFrom,
      slug {
        current
      },
      productDescription,
      variants[]-> {
        ...,
        "isAvailableInRegion": select(
          count(visibilityRegions[]) == 0 || visibilityRegions[] == null || "${getRegion()}" in visibilityRegions[] => true,
          false
        ),
        hideOnBundle[] -> {
          _id
        },
        hideVariantOnGroup[] -> {
          slug {
            current
          }
        },
        selectedOptions[] {
          name,
          value
        },
        prices[] {
          price,
          compareAtPrice,
          currency,
        },
        variantComponents[]-> {
          shopifyIdBase64Encoded,
          price,
          compareAtPrice,
          prices[] {
            price,
            compareAtPrice,
            currency,
          },
          sku,
          variantTitle,
          status {
            backOrderDetailsClassName,
            backorder,
            klaviyoTriggerId,
            preorder,
            watchlist,
            watchlistFormTriggerClassName,
            watchlistDescription[] {
              ...
            }
          },
          ${listingImage}
        },
        ${listingImage},
        ${variantImages}
      }
    },
  },
)`

const product = `
  ...,
  "isAvailableInRegion": select(
    count(visibilityRegions[]) == 0 || visibilityRegions[] == null || "${getRegion()}" in visibilityRegions[] => true,
    false
  ),
  ${mainImage},
  ${crossSell},
  productGroups[] -> {
    ${productGroups},
  },
  productImages[] {
    ${image},
  },
  bundleImages[] {
    ...,
    ${image},
  },
  bundleProductsList[] {
    ${bundleProduct}
  },
  cardFlag -> {
    _type,
    label,
    colorSettings,
  },
  hiddenVariants[] -> {
    shopifyId
  },
  variants[] -> {
    ...,
    "isAvailableInRegion": select(
      count(visibilityRegions[]) == 0 || visibilityRegions[] == null || "${getRegion()}" in visibilityRegions[] => true,
      false
    ),
    selectedOptions[] {
      name,
      value
    },
    hideOnBundle[] -> {
      _id
    },
    prices[] {
      price,
      compareAtPrice,
      currency,
    },
    hideVariantOnGroup[] -> {
      slug {
        current
      }
    },
    variantComponents[]-> {
      shopifyIdBase64Encoded,
      price,
      compareAtPrice,
      prices[] {
        price,
        compareAtPrice,
        currency,
      },
      sku,
      variantTitle,
      status {
        backOrderDetailsClassName,
        backorder,
        klaviyoTriggerId,
        preorder,
        watchlist,
        watchlistFormTriggerClassName,
        watchlistDescription[] {
          ...
        }
      },
      ${listingImage}
    },
    ${listingImage},
    ${variantImages}
  },
  ...select(
    count(youMightAlsoLike[]) > 0 => {
      youMightAlsoLike[]->{
        ${youMightAlsoLike},
        youMightAlsoLike[]->{
          ${youMightAlsoLike}
        },
      },
    },
    count(youMightAlsoLike[]) == 0 => {
      "youMightAlsoLike": *[_type == "shopifyProduct" && enabled == true && isBundle != true][0...3] | order(orderRank asc) {
        ${youMightAlsoLike},
        ...select(
          count(youMightAlsoLike[]) > 0 => {
            youMightAlsoLike[]->{
              ${youMightAlsoLike}
            },
          },
        ),
      },
    }
  ),
  ${openGraph},
  ${contentBuilder},
`

const blogPost = `
  ${mainImage},
  title,
  summary,
  _id,
  _type,
  date,
  slug {
    current,
  },
  categories[] -> {
    _type,
    title,
    group,
    slug {
      current,
    },
    _id,
  },
  author -> {
    firstName,
    lastName,
    bio,
    positionTitle,
    profileImage {
      ${asset}
    }
  },
  ${contentBuilder},
`

const resourcePost = `
  date,
  ${mainImage},
  totalCount,
  title,
  summary,
  _id,
  _type,
  slug {
    current,
  },
  categories[] -> {
    _type,
    title,
    group,
    slug {
      current,
    },
    _id,
  },
  ${contentBuilder},
`

const searchProduct = `
  _type,
  _id,
  slug {
    current,
  },
  title,
  shortTitle,
  shopifyId,
  subtitle,
  priceFrom,
  yotpoProductId,
  enabled,
  isBundle,
  variants[] -> {
    "isAvailableInRegion": select(
      count(visibilityRegions[]) == 0 || visibilityRegions[] == null || "${getRegion()}" in visibilityRegions[] => true,
      false
    ),
    shopifyId,
    sku,
    variantTitle,
    price,
    compareAtPrice,
    availableForSale,
    selectedOptions[] {
      name,
      value
    },
    prices[] {
      price,
      compareAtPrice,
      currency,
    },
  },
  bundleProductsList[] {
    hasCustomVariants,
    discountType,
    discountAmount,
    discountQuantity,
    isOptional,
    ...select(
      hasCustomVariants == true => {
        ...product-> {
          title,
          slug {
            current,
          },
          _id,
          _type,
          shopifyId,
          yotpoProductId,
          productOptions[] {
            ...
          },
        },
        "variants": variants[]->{
          "isAvailableInRegion": select(
            count(visibilityRegions[]) == 0 || visibilityRegions[] == null || "${getRegion()}" in visibilityRegions[] => true,
            false
          ),
          shopifyId,
          sku,
          variantTitle,
          price,
          compareAtPrice,
          availableForSale,
          prices[] {
            price,
            compareAtPrice,
            currency,
          },
        }
      },
      hasCustomVariants != true => {
        ...product-> {
          title,
          slug {
            current,
          },
          _id,
          _type,
          shopifyId,
          yotpoProductId,
          productOptions[] {
            ...
          },
          variants[]-> {
            "isAvailableInRegion": select(
              count(visibilityRegions[]) == 0 || visibilityRegions[] == null || "${getRegion()}" in visibilityRegions[] => true,
              false
            ),
            shopifyId,
            sku,
            variantTitle,
            price,
            compareAtPrice,
            availableForSale,
            prices[] {
              price,
              compareAtPrice,
              currency,
            },
          }
        },
      },
    )
  },
  productGroups[] -> {
    title,
    _id,
    slug {
      current
    }
  },
  ${mainImage}
`

const searchPost = `
  _id,
  _type,
  title,
  summary,
  enabled,
  categories[] -> {
    _id,
    slug {
      current
    },
  },
  ${mainImage},
  slug {
    current
  }
`

const searchCollection = `
  _id,
  _type,
  title,
  hide,
  slug {
    current,
  },
  ${mainImage}
`

const searchQuery = `
  "products": *[_type == "shopifyProduct" && enabled == true] | order(orderRank asc) {
    ${searchProduct}
  },
  "blogPosts": *[_type == "blogPost" && enabled == true] {
    ${searchPost}
  },
  "resourcesPosts": *[_type == "resourcePost" && enabled == true] {
    ${searchPost}
  },
  "groups": *[_type == "productGroup"] {
    ${searchCollection}
  },
  "search": *[_type == "search"][0] {
    defaultProducts[]-> {
      ${searchProduct}
    },
    defaultBlogs[]-> {
      ${searchPost}
    },
    defaultGroups[]-> {
      ${searchCollection}
    }
  }
`

const servicesPolicies = `
  icon {
    svg
  },
  text,
`

const blogAndResourcesCategories = `
  _id,
  _type,
  group,
  title,
  slug {
    current,
  },
`

const headerData = `
"header": *[_type == 'navigationNode' && group == 'header'] | order(orderRank) {
  _id,
  _type,
  title,
  externalUrl,
  visibilityRegions,
  hasProducts,
  textColor,
  internalLink->{
    ...,
    ...select(
      _type == "shopifyProduct" => {
        productGroups[] -> {
          _id,
          slug {
            current
          }
        }
      }
    )
  },
  navImage {
    _key,
    hotspot,
    ${asset},
  },
  group,
  placement,
  includeInMobileNav,
  childNodes[] {
    _id,
    _type,
    title,
    externalUrl,
    visibilityRegions,
    hasProducts,
    internalLink->{
      ...,
      ...select(
        _type == "shopifyProduct" => {
          productGroups[] -> {
            _id,
            slug {
              current
            }
          }
        }
      )
    },
    navImage {
      _key,
      hotspot,
      ${asset},
    },
    group,
    placement,
    includeInMobileNav,
  }
},
"sidebar": *[_type == 'navigationNode' && group == 'secondaryHeader'] | order(orderRank) {
  _id,
  _type,
  title,
  externalUrl,
  visibilityRegions,
  hasProducts,
  textColor,
  "internalLink": internalLink->{
    ...,
    "level1": someReference->{
      ...,
      "level2": anotherReference->{
        ...,
        "level3": nextReference->{
          ...,
          "level4": moreReferences->{
            ...,
            "level5": finalReference->{
              ...,
            },
          },
        },
      },
    },
  },
  navImage -> {
    _key,
    hotspot,
    ${asset},
  },
  group,
  placement,
  includeInMobileNav,
  childNodes[] {
    _id,
    _type,
    title,
    externalUrl,
    visibilityRegions,
    hasProducts,
    "internalLink": internalLink->{
      ...,
      "level1": someReference->{
        ...,
        "level2": anotherReference->{
          ...,
          "level3": nextReference->{
            ...,
            "level4": moreReferences->{
              ...,
              "level5": finalReference->{
                ...,
              },
            },
          },
        },
      },
    },
    navImage -> {
      _key,
      hotspot,
      ${asset},
    },
    group,
    placement,
    includeInMobileNav,
  }
},
${searchQuery}
`

const footerData = `
"navigation": *[_type == 'navigationNode' && group == 'footer'] | order(orderRank) {
  _id,
  _type,
  title,
  externalUrl,
  visibilityRegions,
  hasProducts,
  textColor,
  "internalLink": internalLink->{
    ...,
    "level1": someReference->{
      ...,
      "level2": anotherReference->{
        ...,
        "level3": nextReference->{
          ...,
          "level4": moreReferences->{
            ...,
            "level5": finalReference->{
              ...,
            },
          },
        },
      },
    },
  },
  navImage {
    _key,
    hotspot,
    ${asset},
  },
  group,
  placement,
  includeInMobileNav,
  childNodes[] {
    _id,
    _type,
    title,
    externalUrl,
    visibilityRegions,
    hasProducts,
    "internalLink": internalLink->{
      ...,
      "level1": someReference->{
        ...,
        "level2": anotherReference->{
          ...,
          "level3": nextReference->{
            ...,
            "level4": moreReferences->{
              ...,
              "level5": finalReference->{
                ...,
              },
            },
          },
        },
      },
    },
    navImage {
      _key,
      hotspot,
      ${asset},
    },
    group,
    placement,
    includeInMobileNav,
  }
},
"navigationBelow": *[_type == "navigationNode" && group == "belowFooter"] | order(orderRank) {
  _id,
  _type,
  title,
  externalUrl,
  visibilityRegions,
  hasProducts,
  textColor,
  "internalLink": internalLink->{
    ...,
    "level1": someReference->{
      ...,
      "level2": anotherReference->{
        ...,
        "level3": nextReference->{
          ...,
          "level4": moreReferences->{
            ...,
            "level5": finalReference->{
              ...,
            },
          },
        },
      },
    },
  },
  navImage {
    _key,
    hotspot,
    ${asset},
  },
  group,
  placement,
  includeInMobileNav,
  childNodes[] {
    _id,
    _type,
    title,
    externalUrl,
    visibilityRegions,
    hasProducts,
    "internalLink": internalLink->{
      ...,
      "level1": someReference->{
        ...,
        "level2": anotherReference->{
          ...,
          "level3": nextReference->{
            ...,
            "level4": moreReferences->{
              ...,
              "level5": finalReference->{
                ...,
              },
            },
          },
        },
      },
    },
    navImage {
      _key,
      hotspot,
      ${asset},
    },
    group,
    placement,
    includeInMobileNav,
  }
},
"socials": *[_type == "social"] | order(orderRank) {
  title,
  "href": url,
},
`;

const standalonePage = 
`_id,
_type,
title,
summary,
slug {
  current
},
${openGraph},
${contentBuilder}`

const collectionsLandingPage = 
`title,
pageIntro,
${mainImage},
mobileImage {
  ${asset}
},
subcategories[] -> {
  slug {
    current,
  },
  title,
  visibilityRegions,
  _id,
},
${contentBuilder}`

const blogIndex = 
`title,
pageIntro,
${mainImage},
_id,
_type,
slug {
  current
},
${openGraph}`

const resourceIndex = 
`title,
pageIntro,
_id,
_type,
slug {
  current
},
${openGraph}`

const sanityDiscountCode = `
  code,
  discountType,
  fixedAmount,
  percentageAmount,
  products[]-> {
    _id,
  }
`

exports.projection = {
  collectionsLandingPage,
  blogIndex,
  resourceIndex,
  siteSettings,
  openGraph,
  contentBuilder,
  // hero,
  productCard,
  product,
  blogPost,
  resourcePost,
  // categories,
  searchQuery,
  productGroups,
  servicesPolicies,
  blogAndResourcesCategories,
  headerData,
  footerData,
  standalonePage,
  sanityDiscountCode,
  youMightAlsoLike,
}
