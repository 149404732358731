import { isVisibleIn } from "@src/utils/region";

export const gridCols = {
	1: 'grid-cols-1',
	2: 'grid-cols-2',
	3: 'grid-cols-3',
	4: 'grid-cols-4',
	5: 'grid-cols-5',
	6: 'grid-cols-6',
	7: 'grid-cols-7',
	8: 'grid-cols-8',
	9: 'grid-cols-9',
	10: 'grid-cols-10',
	11: 'grid-cols-11',
	12: 'grid-cols-12',
}

export const mobileGridCols = {
	1: 'max-md:grid-cols-1',
	2: 'max-md:grid-cols-2',
	3: 'max-md:grid-cols-3',
	4: 'max-md:grid-cols-4',
	5: 'max-md:grid-cols-5',
	6: 'max-md:grid-cols-6',
	7: 'max-md:grid-cols-7',
	8: 'max-md:grid-cols-8',
	9: 'max-md:grid-cols-9',
	10: 'max-md:grid-cols-10',
	11: 'max-md:grid-cols-11',
	12: 'max-md:grid-cols-12',
}
const formatShopifyProducts = (items) => {
	return items.filter(product => 
		product.showInProductListing !== false &&
		product.enabled !== false && 
		isVisibleIn(product.visibilityRegions))
};

const formatCollections = (items) => {
	return items.filter(collection => collection.hide !== true)
};

const formatPosts = (items) => {
	return items.filter(posts => posts.enabled !== false)
};

export const formatItemsByType = (items) => {
	if (!items) return [];
	
	const type = items[0]?._type;
	const types = {
		'shopifyProduct': formatShopifyProducts(items),
		'productGroup': formatCollections(items),
		'blogPost': formatPosts(items),
		'resourcePost': formatPosts(items),
	}

	return types[type];
}