function checkLink(str) {
    if(str){
        const matchedValue = {
            type: 'default',
            value: str
        };

        const obj = {
            section: "#",
            klaviyo: ""
        };

        Object.keys(obj).forEach(key => {
            if(str.includes(key + ':')) {
                matchedValue.value = obj[key] + str.replace(key + ':', '');
                matchedValue.type = key;
            }
        });

        return matchedValue;
    }
}

export { checkLink };