import React from 'react'
import { removeFile } from  './helpers'

const FileCard = ({ index, file, setSelectedFiles }) => {
  return (
    <div title={file.name} className="flex flex-col items-center relative bg-white border border-gray-border border-solid p-2">
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.99984 3.33325C9.11578 3.33325 8.26794 3.68444 7.64281 4.30956C7.01769 4.93468 6.6665 5.78253 6.6665 6.66659V33.3333C6.6665 34.2173 7.01769 35.0652 7.64281 35.6903C8.26794 36.3154 9.11578 36.6666 9.99984 36.6666H29.9998C30.8839 36.6666 31.7317 36.3154 32.3569 35.6903C32.982 35.0652 33.3332 34.2173 33.3332 33.3333V13.3333L23.3332 3.33325H9.99984ZM9.99984 6.66659H21.6665V14.9999H29.9998V33.3333H9.99984V6.66659ZM13.3332 19.9999V23.3333H26.6665V19.9999H13.3332ZM13.3332 26.6666V29.9999H21.6665V26.6666H13.3332Z" fill="black"/>
        </svg>
        <span className="text-sm truncate w-[70px] text-center">{file.name}</span>
        <button
            type="button"
            className="absolute top-1 right-1"
            onClick={() => removeFile(index, setSelectedFiles)}
        >
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.8332 5.34175L14.6582 4.16675L9.99984 8.82508L5.3415 4.16675L4.1665 5.34175L8.82484 10.0001L4.1665 14.6584L5.3415 15.8334L9.99984 11.1751L14.6582 15.8334L15.8332 14.6584L11.1748 10.0001L15.8332 5.34175Z" fill="black"/>
            </svg>
        </button>
        <div className="text-xs pt-1">{file.size / 1024 > 1000 ? (file.size / 1024 / 1024).toFixed(1)  + 'MB' : (file.size / 1024).toFixed() + 'KB'}</div>
        {file.isSupported === false && (
            <div className="absolute left-0 bottom-0 right-0 h-2 bg-error"></div>
        )}
    </div>
  )
}

export default FileCard