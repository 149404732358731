import React, { useState, useEffect, useMemo } from "react"
import * as bodyScrollLock from "body-scroll-lock"
import LineItem from "../line-item"
import { useSpring, animated } from "react-spring"
import RichText from "@components/rich-text"
import PromoCodeInput from "@components/cart/promo-code-input"
import AffirmPromotion from "../../widgets/affirm-promotion"
import { getRegionHandle } from "@utils/region"
import { getCookie } from "@analytics/cookie-utils"
import { decode } from "shopify-gid"
import Section from "@components/section"
import { ReactComponent as Close } from "@svgs/close.svg"
import Button from "../../button"
import {
  getDiscountAmount,
  getDiscountDisplayedAmount,
  getDiscountLabel,
  getDiscounts,
  getPrice,
} from "../helpers"
import { trackViewCart } from "@src/services/analytics"
import {
  captureOctaneAddToCart,
  captureOctaneViewCheckout,
  checkForCashbackProducts,
  captureOctaneViewCart,
} from "./helpers"
import CashbackModal from "@src/components/cashback-modal"
import useToggleStates from "@src/hooks/useToggleStates"
import { useCartStore } from "@src/hooks/useStore"
import FocusTrap from "focus-trap-react"
import ClearCart from "../clear-cart"
import CartUpsell from "../upsell"
import { formatPrice } from "@src/utils/products/pricing"

const CartDrawer = () => {
  const [error, setError] = useState("")
  const [cashbackOpened, setCashbackOpened] = useState(false)

  const { toggleAllFunction } = useToggleStates()

  const { addLineItems, addDiscount, removeDiscount, cart, isDrawerOpen, loading } =
    useCartStore([
      "addLineItems",
      "addDiscount",
      "removeDiscount",
      "cart",
      "isDrawerOpen",
      "loading",
    ])

  const { discountAllocations = [] } = cart

  useEffect(() => {
    if (isDrawerOpen) {
      bodyScrollLock.disableBodyScroll(document.body)
    } else {
      bodyScrollLock.enableBodyScroll(document.body)
    }
  }, [isDrawerOpen, cart])

  useEffect(() => {
    if (isDrawerOpen) {
      trackViewCart(cart, cart.lines)
    }
  }, [isDrawerOpen])

  useEffect(() => {
    if (window.location.href.toString().includes("/#view-cart")) {
      toggleAllFunction("drawer", isDrawerOpen)
    }
  }, [])

  useEffect(() => {
    captureOctaneAddToCart(addLineItems, toggleAllFunction)
    captureOctaneViewCart(toggleAllFunction)
    captureOctaneViewCheckout(getCheckoutURL)
  }, [cart?.id])

  const scriptDiscountValue = getPrice(
    (discountAllocations || [])
      .filter(({ __typename }) => __typename === "ScriptDiscountApplication")
      .map(({ value }) => parseFloat(value?.amount || 0))
      .reduce((curr, acc) => curr + acc, 0)
  )

  const discounts = getDiscounts(cart)

  const discountLabel = getDiscountLabel(discounts)
  const discountAmount = getDiscountAmount(discounts)
  const discountDisplayedAmount = getDiscountDisplayedAmount(discounts)

  const priceBeforeTaxes = () => {
    let price = cart.cost?.totalAmount
      ? cart.cost?.totalAmount.amount
      : cart.cost?.totalAmount

    if (price && cart.cost?.totalTaxAmount?.amount) {
      price -= cart.cost?.totalTaxAmount?.amount
    }

    return formatPrice({
      price,
      minimumFractionDigits: 2,
    })
  }
  const cartPrices = () => {
    const price = cart.lines?.reduce((acc, line) => {
      return acc + +line?.cost?.amountPerQuantity?.amount
    }, 0);

    const compareAtPrice = cart.lines?.reduce((acc, line) => {
      return acc + +line?.cost?.compareAtAmountPerQuantity?.amount
    }, 0);

    const compareAtPriceDiscount = compareAtPrice - price;

    return {
      subtotal: compareAtPriceDiscount > 0 ? formatPrice({
        price: compareAtPrice,
        minimumFractionDigits: 2,
      }) : null,
      compareAtPriceDiscount : compareAtPriceDiscount > 0 ? formatPrice({
        price: compareAtPriceDiscount,
        minimumFractionDigits: 2,
      }) : null
    }
  }

  const { subtotal, compareAtPriceDiscount } = cartPrices();

  useEffect(() => {
    if ("URLSearchParams" in window) {
      const couponCode = new URLSearchParams(location.search).get("coupon")

      if (cart && cart.id) {
        if (couponCode) {
          if (discounts?.length > 0) {
            const { code } = discounts[0]

            if (code && code !== couponCode.toUpperCase()) {
              setTimeout(() => {
                addDiscount(couponCode)
              }, 1000)
            }
          } else if (cart?.discountCodes?.length < 1) {
            setTimeout(() => {
              addDiscount(couponCode)
            }, 1000)
          }
        }

        checkForCashbackProducts(cart, addLineItems)
      }
    }
  }, [cart])

  const getCheckoutURL = () => {
    const CJ_REDIRECT_ENDPOINT = "https://www.diggs.pet/api/cj/checkout"
    const region = getRegionHandle()
    const cjEvent = getCookie("cje")
    if (region === "canada" && cjEvent) {
      window.location.href = `${CJ_REDIRECT_ENDPOINT}?url=${cart.checkoutUrl}&cjEvent=${cjEvent}`
    }

    window.location.href = cart.checkoutUrl + "&cid=" + cart.id
  }

  const lineItems = useMemo(() => {
    if (cart.lines.length === 0) {
      return (
        <RichText
          className="text-base text-center"
          paragraphClassName="grow-1"
          childrenHTML="No items in your cart."
        ></RichText>
      )
    }

    return cart.lines
      .filter(
        lineItem =>
          !lineItem.attributes.find(attr => attr.key === "_warranty_offer_id")
      )
      .map((lineItem, index) => {
        const warrantyDetails = cart.lines.find(item =>
          item.attributes.find(
            attr =>
              attr.key === "_variantId" &&
              attr.value === decode(lineItem.variant.id).id
          )
        )

        return (
          <LineItem
            key={lineItem.id.toString()}
            lineItem={lineItem}
            index={index}
            setCashbackOpened={setCashbackOpened}
          />
        )
      })
  }, [cart])

  const handleAddDiscountCode = async discountCode => {
    setError("")

    if (!discountCode || discountCode.length === 0) {
      removeDiscount()
    } else {
      const resp = await addDiscount(discountCode)
      const caughtError = resp === null
      const discountCodeNotApplicable =
        resp &&
        resp.cart.discountCodes.find(code => code.code === discountCode)
          ?.applicable
      const discountErrors = resp && resp.userErrors

      if (caughtError) {
        setError("Something went wrong. Please try again.")
      } else if (
        (discountErrors && discountErrors.length !== 0) ||
        !discountCodeNotApplicable
      ) {
        setError("Please enter a valid discount code")
      }
    }
  }

  const bgAnimateProps = useSpring({
    opacity: isDrawerOpen ? 0.25 : 0,
    from: { opacity: 0 },
  })

  const handleCloseClick = () => {
    toggleAllFunction("drawer", false)
  }

  return (
    <>
      <div
        className={`fixed w-full h-full flex flex-col top-0 left-0 overflow-hidden text-white z-[52] cart-drawer-wrapper ${isDrawerOpen ? "" : "pointer-events-none"}`}
        tabIndex="-1"
      >
        <animated.div
          className="absolute w-full h-full modal-overlay z-20"
          style={bgAnimateProps}
          onClick={handleCloseClick}
        ></animated.div>
        <Section
          innerClassname="absolute right-0 h-full"
          settings={{ classname: "h-full !mt-0", isContainer: true }}
          containerClassname="h-full !mt-0"
        >
          <FocusTrap
            active={isDrawerOpen}
            focusTrapOptions={{
              // onDeactivate: handleCloseClick,
              allowOutsideClick: true,
              clickOutsideDeactivates: true,
            }}
          >
            <div
              className={`cart-drawer ${isDrawerOpen ? "opacity-100 visible" : "opacity-0 invisible"} transition-all !duration-1000 !delay-1000`}
              tabIndex={0}
              aria-hidden="false"
              aria-label="Cart drawer opened"
            >
              <div className="flex flex-col h-full">
                <header className="flex flex-col max-md:gap-y-4 bg-light-gray p-5 rounded-xl">
                  <div className="flex items-center w-full gap-x-2.5">
                    <div className="w-full flex justify-between items-center">
                      <span className="text-xl font-medium">Shopping Cart</span>
                      <button
                        id="cart-drawer-close"
                        type="button"
                        aria-label="Close cart drawer"
                        onClick={handleCloseClick}
                        className="p-4 duration-300 rounded-full cursor-pointer absolute right-7 top-7"
                      >
                        <Close className="w-4" focusable="false" />
                      </button>
                    </div>
                  </div>
                  {/* <ShippingProgressBar
                        show={qualifiesForFreeShipping}
                        priceValue={untilFreeShipping}
                        hasFreeShippingCode={hasFreeShippingCode}
                        overrideText={
                          hasFreeGift()
                            ? "Congrats! Your free gift has been added. 🎁"
                            : null
                        }
                        price={formatPrice({
                          price: untilFreeShipping,
                          minimumFractionDigits: 2,
                        })}
                        width={`${(checkoutSubtotal / shippingThreshold) * 100}%`}
                        link={URLS.PRODUCTS}
                        linkText="Shop Add-Ons"
                      /> */}
                </header>

                <div
                  className={`col-span-6 gap-y-5 pt-5 flex flex-col overflow-x-hidden overflow-y-auto`}
                >
                  <div
                    className="flex flex-col gap-y-2.5"
                    aria-label={"Line items"}
                    tabIndex={0}
                    role={"list"}
                  >
                    {lineItems}
                  </div>

                  <ClearCart />
                  
                  {cart.lines.length > 0 && (
                    <CartUpsell />
                  )}
                </div>

                <footer className="flex flex-col gap-y-5 pt-2.5 mt-auto">
                  <PromoCodeInput
                    onSubmit={handleAddDiscountCode}
                    error={error}
                  />

                  {/* VARIATION */}
                  <div className="hidden flex-col gap-y-2.5 cart-discounts-variation" tabIndex={0}>
                    {subtotal && <p
                      className="flex justify-between"
                      aria-label={`Cart Subtotal: ${subtotal} dollars`}
                    >
                      <span className="antialiased text-soft-black text-base font-medium">
                        Subtotal
                      </span>
                      <span className="text-safety-green antialiased text-base font-medium">
                        {subtotal}
                      </span>
                    </p>}

                    {compareAtPriceDiscount && <p
                      className="flex justify-between"
                      aria-label={`-${compareAtPriceDiscount} off with sesonal product discounts.`}
                    >
                      <span className="antialiased text-soft-black text-base font-medium">
                        {"Discount (Product Discounts)"}
                      </span>
                      <span className="text-steadfast-red antialiased text-base font-medium">
                        -
                        {compareAtPriceDiscount}
                      </span>
                    </p>}

                    {discounts?.length > 0 && (
                      <p
                        className="flex justify-between"
                        aria-label={`-${
                          discountAmount === 0
                            ? scriptDiscountValue
                            : discountDisplayedAmount
                        } off with code ${
                          discountLabel &&
                          discountLabel !== "" &&
                          `(${discountLabel})`
                        }.`}
                      >
                        <span className="antialiased text-soft-black text-base font-medium">
                          Discount{" "}
                          {discountLabel &&
                            discountLabel !== "" &&
                            `(${discountLabel})`}
                        </span>
                        <span className="text-steadfast-red antialiased text-base font-medium">
                          -
                          {discountAmount === 0
                            ? scriptDiscountValue
                            : discountDisplayedAmount}
                        </span>
                      </p>
                    )}

                    <p
                      className="flex justify-between"
                      aria-label={`Cart ${subtotal ? "Grand " : ""}Subtotal: ${priceBeforeTaxes()} dollars`}
                    >
                      <span className="antialiased text-soft-black text-base font-medium">
                        {`${subtotal ? "Grand " : ""}Subtotal`}
                      </span>
                      <span className="text-safety-green antialiased text-base font-medium">
                        {priceBeforeTaxes()}
                      </span>
                    </p>
                  </div>

                  {/* CONTROL */}
                  <div className="flex flex-col gap-y-2.5 cart-discounts-control" tabIndex={0}>
                    {discounts?.length > 0 && (
                      <p
                        className="flex justify-between"
                        aria-label={`-${
                          discountAmount === 0
                            ? scriptDiscountValue
                            : discountDisplayedAmount
                        } off with code ${
                          discountLabel &&
                          discountLabel !== "" &&
                          `(${discountLabel})`
                        }.`}
                      >
                        <span className="antialiased text-soft-black text-base font-medium">
                          Discount{" "}
                          {discountLabel &&
                            discountLabel !== "" &&
                            `(${discountLabel})`}
                        </span>
                        <span className="text-safety-green antialiased text-base font-medium">
                          -
                          {discountAmount === 0
                            ? scriptDiscountValue
                            : discountDisplayedAmount}
                        </span>
                      </p>
                    )}

                    <p
                      className="flex justify-between"
                      aria-label={`Cart Subtotal: ${priceBeforeTaxes()} dollars`}
                    >
                      <span className="antialiased text-soft-black text-base font-medium">
                        Subtotal
                      </span>
                      <span className="text-safety-green antialiased text-base font-medium">
                        {priceBeforeTaxes()}
                      </span>
                    </p>
                  </div>

                  <div className="flex flex-col gap-y-2.5">
                    <Button
                      className={cart.lines.length === 0 ? "bg-opacity-75" : ""}
                      ctaWidth={"full"}
                      theme={"btn-secondary"}
                      onClick={getCheckoutURL}
                      disabled={cart.lines.length === 0 || loading}
                    >
                      Checkout
                    </Button>

                    <AffirmPromotion
                      total={cart.cost?.totalAmount}
                      center={true}
                      type="cart"
                    />
                  </div>
                </footer>
              </div>
            </div>
          </FocusTrap>
        </Section>
      </div>
      <CashbackModal opened={cashbackOpened} setOpened={setCashbackOpened} />
    </>
  )
}

export default CartDrawer
