import { setSiteSettings, setAlert, toggleBackdrop } from "@src/actions/siteSettings"
import React from "react";

const initialState = {
    alert: <></>,
    siteSettings: {
        backdropOpened: false,
    },
}

const siteSettingsSlice = (set) => ({
    ...initialState,

    setSiteSettings: (settings) => setSiteSettings(set, settings),

    setAlert: (alert) => setAlert(set, alert),

    toggleBackdrop: (override) => toggleBackdrop(set, override),

})

export default siteSettingsSlice;