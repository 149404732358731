import React from 'react'
import { ReactComponent as Arrow } from "@svgs/arrow.svg"

const CarouselArrows = ({ sliderRef }) => {
  return (
    <div className='flex items-end justify-end' tabIndex={0} aria-label='Slider Buttons'>
      <button className="flex px-2.5 py-2 cursor-pointer h-fit" onClick={() => sliderRef?.current?.slickPrev()} aria-label='Previous Slide'><Arrow className="slider-arrow rotate-180" /></button>
      <button className="flex px-2.5 py-2 cursor-pointer h-fit" onClick={() => sliderRef?.current?.slickNext()} aria-label='Next Slide'><Arrow className="slider-arrow" /></button>
    </div>
  )
}

export default CarouselArrows