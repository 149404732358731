import { getRegionHandle } from "./region";

const PARAM_NAME = 'cjevent'
const CJ_COOKIE_ENDPOINT_DOMAIN = process.env.CJ_COOKIE_ENDPOINT_DOMAIN || 'https://www.diggs.pet'

const isDefaultRegion = getRegionHandle() === 'default'

let lastValue: string | null = null

function createIframe(url: string) {
    const i = document.createElement('iframe');
    i.style.display = 'none';
    i.onload = function() { i.parentNode?.removeChild(i); };
    i.src = url;
    document.body.appendChild(i);
}

function prefixEndpoint(endpoint: string) {
    if (!isDefaultRegion) {
        return `${CJ_COOKIE_ENDPOINT_DOMAIN}${endpoint}`
    }

    return endpoint
}

export async function setCjCookieFromUrl() {
    const searchParams = new URLSearchParams(window.location.search);
    const cjEvent = searchParams.get(PARAM_NAME)
    
    if (cjEvent && lastValue !== cjEvent) {
        lastValue = cjEvent
        try {
            if (!isDefaultRegion) {
                fetch(`/api/cj/event?cjevent=${cjEvent}`)
                // We load the remote site in an iframe
                createIframe(prefixEndpoint(`/api/cj/event?cjevent=${cjEvent}`))
            } else {
                fetch(`/api/cj/event?cjevent=${cjEvent}`)
            }
            // Load remote
        } catch(e) {
            console.error(e)
        }
    }
}