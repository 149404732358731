import { getCookie } from '@analytics/cookie-utils';

const URLS = {
    canada: process.env.GEOLOCATION_CANADA_URL || 'https://www.diggs.ca',
    default: process.env.GEOLOCATION_US_URL || 'https://www.diggs.pet',
}
const COUNTRY_COOKIE_NAME = '_geoCountry'
const COUNTRY_OVERRIDE_PARAM = '_country'
let bots = [
    // generic
    'bot', // googlebot, bingbot, telegrambot, twitterbot, yandexbot, etc.
    'check',
    'cloud', // cloudflare, cloudinary, etc.
    'crawler',
    'download',
    'monitor', // monitor & monitoring
    'preview', // skypeuripreview, bingpreview, yahoo link preview, etc.
    'scan',
    'spider', // baiduspider, 360spider, screaming frog seo spider, etc.

    // search engines
    'google',
    'qwantify',
    'yahoo',

    // aggregators, messengers and social networks
    'facebookexternalhit',
    'flipboard',
    'tumblr',
    'vkshare',
    'whatsapp',

    // downloaders
    'curl',
    'perl',
    'python',
    'wget',

    // high activity scanners
    'heritrix',
    'ia_archiver',
];
const createRegex = () => new RegExp(`(${bots.join('|')})`, 'i');
let isBotRegex = createRegex();

const isBot = (userAgent: string) => isBotRegex.test(userAgent);

function appendPathToAnotherUrl(url: string, sourceUrl?: URL) {
    if (!sourceUrl) {
        return url
    }

    const target = new URL(url)
    target.pathname = sourceUrl.pathname
    target.search = sourceUrl.search

    return target.toString()
}

function getUrlToRedirectTo(countryCode: string, currentUrl?: URL) {
    const development = process.env.NODE_ENV === "development"
    const region = process.env.REGION || 'default'

    if (development) return null

    if (countryCode.toUpperCase() === 'CA' && region !== 'canada') {
        return appendPathToAnotherUrl(URLS['canada'], currentUrl)
    }

    if (countryCode.toUpperCase() !== 'CA' && region === 'canada') {
        return appendPathToAnotherUrl(URLS['default'], currentUrl)
    }

    return null
}

const getVisitorCountry = async () => {
    if (!process.env.STAGING) return ""
    const result = await fetch('/api/geo').then((response) => response.json())
    return result.country
}

export default async () => {
    const url = new URL(window.location.href);
    const overrideCountry = url.searchParams.get(COUNTRY_OVERRIDE_PARAM)
    const countryCodeFromCookie = getCookie(COUNTRY_COOKIE_NAME);
    let countryCode = overrideCountry || countryCodeFromCookie || await getVisitorCountry();

    const userAgent = navigator.userAgent;
    const isABot = isBot(userAgent)

    if (isABot) return;

    if (countryCode) {
        const urlToRedirectTo = getUrlToRedirectTo(countryCode, url);

        if (urlToRedirectTo && urlToRedirectTo !== window.location.href) {
            window.location.href = urlToRedirectTo;
        }
    }
}