import { Helmet } from "react-helmet"
import React from "react"

export const TokiWallet = ({ designId = "" }) => {
  return (
    <>
      <div id="toki-shopify-integration-embed-wallet-v2-root" />
      <Helmet>
        <script
          id="toki-shopify-integration-embed-wallet-v2-settings"
          type="application/json"
        >
          {JSON.stringify({ designId })}
        </script>
      </Helmet>
    </>
  )
}
