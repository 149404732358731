import { v4 as uuidv4 } from 'uuid';
import Cookies from 'js-cookie';

const utmParameters = [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_content",
    "utm_term"
];

const clickIds = [
    "gclid", // Google click ID
    "fbclid", // Facebook click ID
    "ttclid", // TikTok click ID
    "irclickid", // Impact Radius click ID
];


const toObject = (arr: { [key: string]: string | null }[]) => arr.reduce((curr, prev) => ({ ...curr, ...prev }), {});

const urlParamsToObject = (params: string[], searchParams: URLSearchParams) => {
    return toObject(params.map((param) => ({
        [param]: searchParams.get(param)
    })))
}

export const localStorageKey = 'diggs_visitor_info';

export const getStoredParams = () => {
    if (localStorage.getItem(localStorageKey)) {
        return JSON.parse(localStorage.getItem(localStorageKey) as string);
    }

    return {};
}

const generateUserId = () => uuidv4();

export const storeVisitorInfo = (queryString: string, pathname: string = "/") => {
    const urlParams = new URLSearchParams(queryString);
    const newUtmParams = utmParameters.filter((param) => urlParams.has(param));
    const newClickIds = clickIds.filter((param) => urlParams.has(param));
    const storedParams = getStoredParams();
    let newParams = urlParamsToObject([...newUtmParams, ...newClickIds], urlParams); // first visit
    const landingPage = { landing_page: pathname };

    if (Object.keys(storedParams).length !== 0) {
        const hasNewUtms = newUtmParams.find((param) => storedParams[param] !== urlParams.get(param)) !== undefined;
        const existingClickIds = toObject(Object.keys(storedParams)
            .filter((param) => clickIds.indexOf(param) !== -1)
            .map((param) => ({ [param]: storedParams[param] })));

        if (hasNewUtms || newClickIds.find((param) => param === 'gclid')) {
            delete existingClickIds.gclid;

            newParams = { ...existingClickIds, ...newParams, ...landingPage };
        } else if (hasNewUtms && existingClickIds.gclid) {
            newParams = { ...existingClickIds, ...newParams, ...landingPage }
            delete newParams.gclid;
        } else if (newClickIds.length > 0) {
            // If in a subsequent visit a click id other than the gclid is present (fbclid, ttclid, ...) without accompanying utms, save this value without making changes to the existing parameters.
            newParams = { ...storedParams, ...urlParamsToObject(newClickIds, urlParams) };
        } else {
            newParams = storedParams
        }
    } else {
        newParams = { ...newParams, ...landingPage }
    }

    newParams.user_id = storedParams.user_id || generateUserId();
    localStorage.setItem(localStorageKey, JSON.stringify(newParams));
}

export const getCheckoutAttributes = () => {
    const storedParams = getStoredParams();

    const visitorInfo = Object.keys(storedParams).length !== 0 ? {
        "key": "_elevar_visitor_info",
        "value": JSON.stringify(storedParams)
    } : null;

    const cookies = Cookies.get();

    const ga4Cookies = Object.keys(cookies).filter((cookieKey) => cookieKey.startsWith("_ga_"));
    const cjeCookies = Object.keys(cookies).filter((cookieKey) => cookieKey.startsWith("cje"));

    return [
        visitorInfo,
        ...["_fbc", "_fbp", "_ga", ...ga4Cookies, ...cjeCookies].map((cookieId) => ({
            "key": `_elevar_${cookieId}`,
            "value": cookies[cookieId]
        })).filter((attribute) => attribute.value !== undefined),
    ].filter((attr) => attr !== null);
}
