import React, { useRef } from "react"
import Slider from "react-slick"
import { formatItemsByType } from "../helpers"
import { Link, navigate } from "gatsby"
import { getUriFromDocument } from "@src/utils/routing"
import Image from "@src/components/image"
import CarouselArrows from "@src/components/carousel/arrows"
import { useIsMobile } from "@src/utils/general"
import Button from "@src/components/button"
import { useSearchStore } from "@src/hooks/useStore"
import useToggleStates from "@src/hooks/useToggleStates"
import { trackSelectItem } from "@src/services/analytics"
import { getRegionShopUrl } from "@src/utils/region"

const chunkArray = (array, size, isMobile) => {
  const result = []

  for (let i = 0; i < array?.length; i += size) {
    result.push(array.slice(i, i + size))
  }

  if (result.length < 2 && !isMobile) {
    result.push([])
  }

  return result
}

export default function SearchSlider({ heading, items }) {
  const { query, defaultItems } = useSearchStore(["query", "defaultItems"])

  const sliderRef = useRef()
  const isMobile = useIsMobile()
  const hasDefaultItems = !query || query === ""

  const { toggleAllFunction } = useToggleStates()

  const itemsToDisplay = formatItemsByType(
    hasDefaultItems ? defaultItems.products : items
  )

  // Split the items into chunks of 3
  const chunkedItems = chunkArray(itemsToDisplay, 3, isMobile)

  const sliderSettings = {
    infinite: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: true,
    className: "search-products-slider flex",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  const handleClick = () => {
    toggleAllFunction("search", false)
  }

  const trackItem = item => {
    if (item._type === "shopifyProduct") {
      console.log(item, "adsasdadsasdasd")
      const isBundle = item.isBundle

      if (isBundle) {
        trackSelectItem(
          item.bundleProductsList.map(product => {
            const bundleVariant = product.variants[0]
            return {
              merchandise: {
                id: bundleVariant.shopifyId,
                sku: bundleVariant.sku,
                title: bundleVariant.variantTitle,
                price: Number(bundleVariant.price)?.toFixed(2),
                compareAtPrice: Number(bundleVariant.compareAtPrice)?.toFixed(
                  2
                ),
                image: {
                  src: "",
                },
                product: {
                  id: product.shopifyId || product.yotpoProductId,
                  title: product.title,
                  onlineStoreUrl:
                    getRegionShopUrl() + getUriFromDocument(product),
                },
              },
            }
          }),
          "search results"
        )
      } else {
        const variant = item.variants[0]
        trackSelectItem(
          [
            {
              merchandise: {
                id: variant.shopifyId,
                sku: variant.sku,
                title: variant.variantTitle,
                price: Number(variant.price)?.toFixed(2),
                compareAtPrice: Number(variant.compareAtPrice)?.toFixed(2),
                image: {
                  src: "",
                },
                product: {
                  id: item.shopifyId || item.yotpoProductId,
                  title: item.title,
                  onlineStoreUrl: getRegionShopUrl() + getUriFromDocument(item),
                },
              },
            },
          ],
          "search results"
        )
      }
    }
  }

  return (
    <>
      {itemsToDisplay?.length > 0 ? (
        <div className="flex flex-col md:gap-y-2.5 gap-y-small w-full">
          <div className="flex items-center justify-between">
            <p className="font-medium md:pl-2.5">{heading}</p>
            {(chunkedItems.length > 2 ||
              (isMobile && chunkedItems[1]?.length > 0)) && (
              <CarouselArrows sliderRef={sliderRef} />
            )}
          </div>
          <Slider ref={sliderRef} {...sliderSettings}>
            {chunkedItems.map((chunk, index) => (
              <div
                key={index}
                className="!grid grid-rows-3 gap-x-5 gap-y-2.5 grid-cols-1 md:px-2.5"
                data-index={index}
              >
                {chunk.map(item => {
                  if (item !== null) {
                    const { title, shortTitle, mainImage, subtitle, _type } =
                      item
                    return (
                      <Link
                        to={getUriFromDocument(item)}
                        key={item._id}
                        className="w-full"
                        onClick={() => {
                          handleClick()
                          trackItem(item)
                        }}
                      >
                        <div className="flex gap-2.5 row-span-1 border-1 border-solid border-darker-gray rounded-xl overflow-hidden p-2">
                          <div className="md:max-w-20 max-w-1/3 w-full aspect-[4/3] flex">
                            <Image
                              image={mainImage}
                              width={80}
                              height={60}
                              className="rounded-xl !opacity-100"
                            />
                          </div>
                          <div className="max-w-full w-full flex flex-col justify-center gap-y-2.5">
                            <span className={`text-inherit w-fit font-medium`}>
                              {shortTitle || title}
                              {_type === "shopifyProduct" && (
                                <span className="text-darker-gray font-normal">
                                  {" - "}
                                  {subtitle}
                                </span>
                              )}
                            </span>
                          </div>
                        </div>
                      </Link>
                    )
                  }
                })}
              </div>
            ))}
          </Slider>

          <div className="flex w-full md:px-2.5">
            <Button
              theme="btn-transparent"
              ctaWidth={"full"}
              className="animate-slideup"
              onClick={() => {
                navigate("/collections/")
                toggleAllFunction("search", false)
              }}
            >
              View all {heading.toLowerCase()}
            </Button>
          </div>
        </div>
      ) : (
        <div className={"h-full flex items-center justify-center"}>
          <span>No results for &ldquo;{query || ""}&rdquo;</span>
        </div>
      )}
    </>
  )
}
