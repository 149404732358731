import { useRef, useCallback } from "react";

const currentTransformValue = (ref) => {
	return parseFloat(ref.current.innerSlider.track.props.trackStyle.transform?.match(/(-?\d+(?:\.\d+)?px)/)[0])
}

const getSlide = (ref, index = 0) => {
	if (!ref.current) return;
	const slide = [...ref.current?.innerSlider?.track?.node.querySelectorAll('.slick-slide')].find((slide) => +slide.dataset.index === +ref.current.innerSlider.track.props.currentSlide + index)

	return slide ? slide : null;
}

const useCarousel = () => {
	const sliderRef = useRef();

	const getCurrentIndex = () => { return sliderRef.current?.innerSlider.track.props.currentSlide; }

	const getSliderTrack = () => { return sliderRef.current?.innerSlider.track.node; }

	const animateRight = () => {
		if (sliderRef.current === null) return;
		getSliderTrack().style.transform = `translate3d(${currentTransformValue(sliderRef) - 100}px, 0, 0)`;
		getSliderTrack().style.transition = `-webkit-transform 500ms ease 0s`;

		getSliderDot(getCurrentIndex()) && getSliderDot(getCurrentIndex()).classList.add('dot-left-empty');
		getSliderDot(getCurrentIndex() + 1) && getSliderDot(getCurrentIndex() + 1).classList.add('dot-left-full')
	}

	const animateLeft = () => {
		if (sliderRef.current === null) return;
		getSliderTrack().style.transform  = `translate3d(${currentTransformValue(sliderRef) + 100}px, 0, 0)`;
		getSliderTrack().style.transition = `-webkit-transform 500ms ease 0s`;

		getSliderDot(getCurrentIndex()) && getSliderDot(getCurrentIndex()).classList.add('dot-right-empty');
		getSliderDot(getCurrentIndex() - 1) && getSliderDot(getCurrentIndex() - 1).classList.add('dot-right-full')
	}
	
	const animateBack = () => {
		if (sliderRef.current === null) return;
		getSliderTrack().style.transform  = `translate3d(${currentTransformValue(sliderRef)}px, 0, 0)`;
		getSliderTrack().style.transition = `-webkit-transform 500ms ease 0s`;

		getSliderDot(getCurrentIndex()) && getSliderDot(getCurrentIndex()).classList.remove('dot-right-empty', 'dot-right-full', 'dot-left-empty', 'dot-left-full')
		getSliderDot(getCurrentIndex() + 1) && getSliderDot(getCurrentIndex() + 1).classList.remove('dot-right-empty', 'dot-right-full', 'dot-left-empty', 'dot-left-full')
		getSliderDot(getCurrentIndex() - 1) && getSliderDot(getCurrentIndex() - 1).classList.remove('dot-right-empty', 'dot-right-full', 'dot-left-empty', 'dot-left-full')
	}

	const getSliderDot = (index) => {
		return sliderRef.current?.innerSlider.list.parentNode.querySelector(`.slider-dot[data-index='${index}']`) ? sliderRef.current?.innerSlider.list.parentNode.querySelector(`.slider-dot[data-index='${index}']`) : null
	}

	const hoverAnimation = useCallback(() => {
		getSlide(sliderRef)?.removeEventListener('mouseenter', animateRight);
		getSlide(sliderRef)?.removeEventListener('mouseenter', animateLeft);

		getSlide(sliderRef, 1)?.addEventListener('mouseenter', animateRight);
		getSlide(sliderRef, -1)?.addEventListener('mouseenter', animateLeft);
		
		getSlide(sliderRef, 1)?.addEventListener('mouseleave', animateBack);
		getSlide(sliderRef, -1)?.addEventListener('mouseleave', animateBack);
	}, [])

	const ignoreHiddenItems = (ref, selector) => {
		const reference = ref || sliderRef;

		const slides = reference.current.innerSlider.track.node.querySelectorAll('.slick-slide');
		[...slides].forEach(slide => {
			const selectedItems = slide.querySelectorAll(selector);

			if (slide.getAttribute('tabIndex') === '-1' && slide.getAttribute('aria-hidden') === 'true') {
				[...selectedItems].forEach(item => {
					item.setAttribute('tabIndex', '-1');
				})
			} else {
				[...selectedItems].forEach(item => {
					item.setAttribute('tabIndex', '0');
				})
			}
		})
	}

	return { sliderRef, hoverAnimation, ignoreHiddenItems }
}

export default useCarousel;