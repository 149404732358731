import { useEffect, useCallback, useRef } from 'react';

const useHeaderClass = () => {
  const headerRef = useRef(null);

  useEffect(() => {
    headerRef.current = document.querySelector('header');
  }, []);

  const onPin = useCallback(() => {
    headerRef.current?.classList.add('header--pinned');
    headerRef.current?.classList.remove('header--unfixed');
  }, []);

  const onUnpin = useCallback(() => {
    headerRef.current?.classList.remove('header--pinned');
    headerRef.current?.classList.remove('header--unfixed');
  }, []);

  const onUnfix = useCallback(() => {
    headerRef.current?.classList.add('header--unfixed');
    headerRef.current?.classList.remove('header--pinned');
  }, []);

  const onSidebarOpened = useCallback(() => {
    headerRef.current?.classList.add('header--sidebar-opened');
  }, []);

  const onSidebarClosed = useCallback(() => {
    headerRef.current?.classList.remove('header--sidebar-opened');
  }, []);

  return {
    onPin,
    onUnpin,
    onUnfix,
    onSidebarOpened,
    onSidebarClosed,
  };
};

export default useHeaderClass;