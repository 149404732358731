export const getScript = (src, callback) => {
  const script = document.createElement("script")
  script.src = src

  script.onload = script.onreadystatechange = function (_, isAbort) {
    if (
      isAbort ||
      !script.readyState ||
      /loaded|complete/.test(script.readyState)
    ) {
      script.onload = script.onreadystatechange = null

      if (!isAbort && callback) setTimeout(callback, 0)
    }
  }

  document.head.appendChild(script)
}
