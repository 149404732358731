export const handleFileChange = (e, setSelectedFiles) => {
    setSelectedFiles((prevFiles) => [...prevFiles, ...e.target.files]);
};

export const removeFile = (indexToRemove, setSelectedFiles) => {
    setSelectedFiles((prevFiles) => {
        return prevFiles.filter((_, index) => index !== indexToRemove);
    });
};

export const validateFile = (selectedFiles) => {
    const acceptedFiles = ['image/jpeg', 'image/png'];
    selectedFiles?.map(file => file.isSupported = acceptedFiles.includes(file.type));
    const validateFileType = selectedFiles.filter(file => !file.isSupported);
  
    const fileByteLength = selectedFiles?.map(file => file.size).reduce((a, b) => a + b, 0);
  
    if(fileByteLength > 25000000) {
      return `File size should be less than 25MB | Current size ${Math.round(fileByteLength / 1024 / 1024)}MB`;
    } else if (selectedFiles?.length > 5) {
      return `No more than 5 files at once`;
    } else if (validateFileType.length > 0) {
      return 'Unsupported file type'
    }
  
    return true;
};