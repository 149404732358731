import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from "gatsby"
import { getTextColorFromHex, linkProps, onEnterKeyPress, useIsMobile } from '@utils/general';
import {ReactComponent as Arrow} from '@svgs/arrow.svg'
import {ReactComponent as ArrowDown} from '@svgs/arrow-down.svg'
import Image from '../image';
import FocusTrap from 'focus-trap-react';
import { ReactComponent as Close } from "@svgs/close.svg";
import AlertDialog from '../alert';
import { getUriFromDocument } from '@src/utils/routing';
import Button from '../button';
import { useSiteSettingsStore } from '@src/hooks/useStore';

export const NavMenu = ({ data, className }) => {
  const navRef = useRef();
  const [opened, setOpened] = useState();

  const clickEventListener = useCallback((e) => {
    if (!navRef.current.contains(e.target) && opened !== null) {
      setOpened(null);
    }
  }, [opened])

  useEffect(() => {
    if (navRef.current) {
      document.addEventListener("click", clickEventListener)

      return () => document.removeEventListener("click", clickEventListener);
    }
  }, [navRef, opened])

  return (
    <nav aria-label='Main Menu' className={className} ref={navRef}>
      <ul className='flex gap-x-5 lg:gap-x-7 gap-y-2 flex-wrap items-center justify-center'>
        {data?.map((nav, index) => <NavMenuItem key={index} nav={nav} index={index} navRef={navRef} setOpened={setOpened} opened={opened} />)}
      </ul>
    </nav>
  )
}

const NavMenuItem = ({ nav, index, setOpened, opened }) => {
  const { title, internalLink, externalLink, childNodes, textColor } = nav
  const isMobile = useIsMobile();

  const handleClick = (index) => {
    setOpened(opened === index ? null : index);
  };

  if (childNodes?.length > 0) {
    return (
      <li 
        key={React.useId()}
        className='group'
      >
        <p onKeyDown={(e) => onEnterKeyPress(e, () => handleClick(index))} tabIndex={0} onClick={() => handleClick(index)} className={`text-lg leading-none font-normal flex gap-x-1 group-hover:text-safety-green transition-all duration-300 cursor-pointer ${getTextColorFromHex(textColor) || "text-black"}`}>
          {title}
          <ArrowDown className="fill-black group-hover:fill-safety-green transition-all duration-300" />
        </p>
        
        {!isMobile && opened === index && <InnerNavMenu title={title} childs={childNodes} setOpened={setOpened} active={opened === index} />}
      </li>
    )
  }

  return (
    <li key={React.useId()}>
      <Link {...linkProps(internalLink, externalLink)} className={`${getTextColorFromHex(textColor) || "text-black"}`} title={title} onClick={handleClick} onKeyDown={(e) => onEnterKeyPress(e, handleClick)}>
        {title}
      </Link>
    </li>
  )
}

export const InnerNavMenu = ({ title, childs, setOpened, active }) => {
  const [activeElement, setActiveElement] = useState(childs[0] || null);
  const { setAlert } = useSiteSettingsStore(["setAlert"]);

  useEffect(() => {
    setAlert(<AlertDialog>{`Subnavigation menu for, ${title}, has been opened`}</AlertDialog>)
  }, [active])

  const handleClose = () => {
    setAlert(<AlertDialog>{`Subnavigation menu for, ${title}, has been closed`}</AlertDialog>)
    setOpened(null);
  }

  return (
    <FocusTrap
      active={active}
      focusTrapOptions={{
        initialFocus: `#inner-nav-item-${title?.toLowerCase()}-0`,
        allowOutsideClick: true,
        clickOutsideDeactivates: true
      }}
    >
      <div 
        className={`block transition-all absolute top-full max-w-4xl w-full left-1/2 -translate-x-1/2 `}
      >
        <div className='pt-5'>
          <div className='bg-white p-5 rounded-xl grid grid-cols-12 gap-x-5'>
            <nav aria-label={`${title} Menu`} className='col-span-5 flex flex-col gap-y-2.5 relative'>
              <p className='text-2xl leading-none font-medium'>{title}</p>
              <ul className='flex flex-col'>
                <span className="absolute top-2.5 right-2.5 group" aria-label='Close Subnavigation Menu' tabIndex={0} onClick={handleClose} role={"button"} aria-hidden={false} onKeyDown={(e) => onEnterKeyPress(e, handleClose)}>
                  <Close className={'w-3 hidden group-focus:block'} />
                </span>
                {childs?.map((child, index) => (
                  <Link 
                    key={`inner-nav-item-${index}`}
                    id={`inner-nav-item-${title?.toLowerCase()}-${index}`}
                    className='flex justify-between relative py-2.5 text-lg font-medium border-b last:border-b-0 border-darker-gray cursor-pointer group/item hover:text-safety-green transition-all duration-300'
                    onMouseEnter={() => child.title !== activeElement.title && setActiveElement(child)}
                    title={child.title}
                    aria-label={child.title || "Navigation Link"}
                    {...linkProps(child.internalLink, child.externalLink)}
                    onClick={handleClose}
                  >
                    {child.title}
                    <Arrow className="w-4 absolute right-2.5 top-1/2 -translate-y-1/2 group-hover/item:fill-safety-green transition-all duration-300" />
                  </Link>   
                ))}
              </ul>
            </nav>
            

            <div className='col-span-7 flex flex-col gap-y-5'>
              <div className={`relative h-full rounded-xl overflow-hidden flex items-center justify-center`}>
                <Image 
                  image={activeElement.navImage} 
                  className={'!absolute top-0 w-full h-full'} 
                  alt={activeElement?.navImage?.asset?.alt}
                  title={activeElement?.navImage?.asset?.alt}
                />
                <div className='bg-black opacity-20 w-full h-full absolute top-0 z-[5]'></div>
                <div className='text-2xl leading-none z-10 relative text-center font-medium text-white'>{activeElement.title}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>
  )
}
