import { Helmet } from "react-helmet"
import React, { useEffect } from "react"

export const TokiReferAFriend = () => {
  useEffect(() => {
    if (typeof window.TokiInit === 'function') window?.TokiInit()
  }, [])

  return (
    <>
      <div
        id="toki-shopify-integration-block-refer-a-friend-root"
        className="toki-css-reset"
      />
      <Helmet>
        <script
          id="toki-shopify-integration-block-refer-a-friend-settings"
          type="application/json"
        >
          {JSON.stringify({
            designId: "54738c8b-4596-4bee-b9ff-84cc9ca74c8c",
            moduleType: "referAFriend",
          })}
        </script>
      </Helmet>
    </>
  )
}
