export interface Region {
    name: string;
    currencyCode: string;
    handle: string;
    isoCode: string;
    shopUrl: string;
}

const REGIONS: Record<string, Region> = {
    default: {
        name: 'Global',
        handle: 'us',
        isoCode: 'US',
        currencyCode: 'USD',
        shopUrl: 'https://diggs.pet'
    },
    canada: {
        name: 'Canada',
        handle: 'canada',
        isoCode: 'CA',
        currencyCode: 'CAD',
        shopUrl: 'https://diggs.ca'
    }
}

const sizeMap = {
    "Small": "S",
    "Medium": "M",
    "Large": "L",
    "Extra Large": "XL",
}

const configurationMap = {
    "Bowl + Crate Clip": "Bowl + Crate Clip",
    "Bowl": "Bowl",
}

type RegionName = keyof typeof REGIONS

export function getRegion(regionName: RegionName) {
    return REGIONS?.[regionName] || REGIONS['default']
}

// https://www.contentkingapp.com/academy/link-rel/#:~:text=link%20rel%3D%22alternate%22%20hreflang,-The%20hreflang%20attribute&text=The%20rel%3D%22alternate%22%20hreflang,%22English%20in%20Canada%22).
// <link rel="alternate" hreflang="en" href="https://www.diggs.com/" />
// <link rel="alternate" hreflang="en-ca" href="https://www.diggs.ca/" />
export function getRegionLinkHrefs(currentUrl?: string) {
    // @todo
    const links = [
        {
            href: 'https://www.diggs.pet',
            hrefLang: 'x-default',
        },
        {
            href: 'https://www.diggs.pet',
            hrefLang: 'en',
        },
        {
            href: 'https://www.diggs.ca',
            hrefLang: 'en-CA',
        },
    ]

    if (currentUrl) {
        return links.map((alternate) => {
            alternate.href = setDomainOfUrl(currentUrl, alternate.href)
            
            return alternate
        })
    }

    return links
}

function setDomainOfUrl(url: string, domain: string) {
    const instance = new URL(url)
    instance.hostname = domain.replace('https://', '')

    return instance.toString()
}

export function getRegionShopUrl() {
    return getCurrentRegion().shopUrl;
}

export function getRegionCurrency() {
    return getCurrentRegion().currencyCode
}

export function getRegionIsoCode() {
    return getCurrentRegion().isoCode
}

export function getRegionHandle() {
    return getCurrentRegion().handle
}

export function getRegionName(): RegionName {
    return (process.env.REGION || 'default').toLowerCase()
}

export function getCurrentRegion(): Region {
    return getRegion(getRegionName())
}

export function isVisibleIn(regions) {
    const region = getCurrentRegion().handle;
    return (
        (regions && regions.includes(region)) ||
        !regions || regions.length === 0
    );
}

export function mapSizeValue(value) {
    if (!value) return value;

    if (Object.keys(sizeMap).includes(value)) {
        return sizeMap[value]
    }

    if (Object.keys(configurationMap).includes(value)) {
        return configurationMap[value]
    }

    return value
}

export function mapSizeValues(options) {
    if (!options) return options;

    options.forEach(option => {
        const { name, value } = option

        if (name === "Size" && Object.keys(sizeMap).includes(value)) {
            option.value = sizeMap[value]
        }

        if (name === "Configuration" && Object.keys(configurationMap).includes(value)) {
            option.value = configurationMap[value]
        }
    });
}