import getValue from 'get-value'

export function getPageTypeFromPath(path: string) {
  const typeMap = {
    "/": "home",
  }

  const productURLPattern: RegExp = /^\/[^/]+\/[^/]+\/[^/]+\/?$/;

  if (path.includes("collections/") && productURLPattern.test(path)) {
    return "product"
  }

  if (path.includes("collections/")) {
    return "category"
  }

  return getValue(typeMap, path, { default: "other" })
}