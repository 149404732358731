import React, { useEffect } from "react"
import getValue from "get-value"
import * as bodyScrollLock from "body-scroll-lock"
import { getUriFromDocument } from "./routing"
import AlertDialog from "@src/components/alert"

const BGCOLORS = {
  transparent: "bg-transparent",
  "#000000": "bg-black",
  "#FFFFFF": "bg-white",
  "#44A043": "bg-safety-green",
  "#FFEE91": "bg-calm-yellow",
  "#88B2DF": "bg-gentle-blue",
  "#FFB643": "bg-social-orange",
  "#FE4A25": "bg-steadfast-red",
  "#BDA2E9": "bg-protected-purple",
  "#03304A": "bg-true-navy",
  "#191818": "bg-soft-black",
  "#C7C7C7": "bg-darker-gray",
  "#D9D9D9": "bg-gray",
  "#F9F9F9": "bg-light-gray",
}

const TEXTCOLORS = {
  "#000000": "text-black",
  "#FFFFFF": "text-white",
  "#44A043": "text-safety-green",
  "#191818": "text-soft-black",
  "#C7C7C7": "text-darker-gray",
  "#D9D9D9": "text-gray",
  "#FE4A25": "text-steadfast-red",
}

export const getBgColorFromHex = hex => {
  const val = getValue(hex, "value")

  if (val) {
    hex = val
  }

  if (!hex) {
    return ""
  }

  if (val && val.includes("bg-")) {
    return val
  }

  return getValue(BGCOLORS, hex, { default: "" })
}

export const getTextColorFromHex = hex => {
  const val = getValue(hex, "value")

  if (val) {
    hex = val
  }

  if (!hex) {
    return ""
  }

  if (val && val.includes("bg-")) {
    return val
  }

  return getValue(TEXTCOLORS, hex, { default: "" })
}

export const getAssetDataFromImage = image => {
  const ref = getValue(image, "asset._ref")
  const id = getValue(image, "asset._id", ref)

  return id
}

export const isNumeric = str => {
  if (typeof str != "string") return false // we only process strings!
  return (
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ) // ...and ensure strings of whitespace fail
}

export const getAtcLabels = (variant, siteSettings) => {
  const cartLabels = getValue(siteSettings, "cartLabels");

  let addToCartLabel = cartLabels?.defaultLabel
  if (variant?.status) {
    if (variant.status.backorder) {
      addToCartLabel = cartLabels?.backorderLabel
    } else if (variant.status.preorder) {
      addToCartLabel = cartLabels?.preorderLabel
    } else if (variant.status.watchlist) {
      addToCartLabel = cartLabels?.watchlistLabel
    }
  }

  return addToCartLabel
}

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = React.useState(false)

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768)
  }, [])

  return isMobile
}

export const toggleBodyScrollLock = async (element, condition, callback) => {
  if (condition) {
    await bodyScrollLock.disableBodyScroll(element)

    // await new Promise((resolve) => setTimeout(resolve, 100));

    if (typeof callback === "function") callback()
  } else {
    await bodyScrollLock.enableBodyScroll(element)
  }
}

export const imageMobileOnly = (mobileRef, desktopRef) => {
  if (!mobileRef && !desktopRef) return

  if (useIsMobile() && mobileRef) {
    return mobileRef
  }

  return desktopRef
}

export const linkProps = (internal, external, href) => {
  return {
    href: external && !internal ? external : href || null,
    to: internal ? getUriFromDocument(internal) || internal : null,
  }
}

export const onEnterKeyPress = (e, callback) => {
  if (e.key === "Enter") {
    callback()
  }
}

export const listenKlaviyoForms = setAlert => {

  window.addEventListener("klaviyoForms", e => {
    setAlert(<AlertDialog>Klaviyo form opened</AlertDialog>)

    e.target.focus()
  })
}
