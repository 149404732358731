import React, { useState } from "react"
import { getVideoTypeAndData, renderVideo } from "./helpers"
import Image from "@components/image"
import { ReactComponent as PlayIcon } from "@svgs/play-icon.svg"

const aspectRatioClasses = {
  '16/9': 'aspect-[16/9]',
  '4/1': 'aspect-[4/1]',
  '3/1': 'aspect-[3/1]',
  '2/1': 'aspect-[2/1]',
  '1/1': 'aspect-1',
};

const VideoBlock = ({ placeholderImage, url, autoplay, controls, muted, loop, vimeoVideoId, aspectRatio, title, video, maxWidth }) => {
  const [isLoaded, setIsLoaded] = useState((autoplay || !placeholderImage))
  const size = {
    maxWidth
  }

  const aspectRatioClassName = aspectRatioClasses[aspectRatio] || aspectRatioClasses['16/9'];

  return (
    <div className="content-builder w-full h-full flex flex-col gap-y-5 justify-center overflow-hidden rounded-xl">
      {title && <h2 tabIndex={0} className="pl-0 md:pl-10">{title}</h2>}
      <div className={`relative max-h-screen w-full ${aspectRatioClassName} rounded-xl overflow-hidden`}>
        {!isLoaded && (
          <button
            className="absolute top-0 left-0 z-20 flex w-full h-full bg-transparent"
            onClick={() => setIsLoaded(true)}
            aria-label='Play Video'
          >
            <span className="hidden">Play video</span>
            <PlayIcon className="w-24 h-24 m-auto md:w-32 md:h-32" viewBox="0 0 167 167" />
          </button>
        )}
        {isLoaded &&
          renderVideo(
            getVideoTypeAndData(video, url, vimeoVideoId),
            autoplay, 
            muted, 
            controls, 
            loop, 
            isLoaded, 
            placeholderImage, 
            size
          )
        }
        {placeholderImage && <Image image={placeholderImage} maxWidth={1800} />}
      </div>
    </div>
  );
};

export default VideoBlock