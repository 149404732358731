import React from 'react'
import Button from '@src/components/button';
import { useCartStore } from '@src/hooks/useStore'
import { ReactComponent as Trash } from "@svgs/trash.svg";

const ClearCart = () => {
  const { itemsRemoved, clearCart, loading } = useCartStore(["itemsRemoved", "clearCart", "loading"]);

  return (
    <Button
      ctaWidth={"fit"}
      className={`${itemsRemoved > 1 ? "flex" : "hidden"} disabled:cursor-not-allowed disabled:opacity-70 rounded-full mx-auto p-2.5 text-sm font-medium items-center gap-x-1 justify-center`}
      theme={"btn-transparent-dark"}
      onClick={clearCart}
      arrow='none'
      disabled={loading}
    >
      <Trash />
      Clear All
    </Button>
  )
}

export default ClearCart