export const videoSlideHandler = (oldIndex, newIndex) => {
  const videoSlides = [...document.querySelectorAll('.pdp--main-slider.is-pdp .slick-slide:has(.video-slide)')];
  const thumbnailSlider = document.querySelector('.pdp--thumbnail-slider.is-pdp');
  const root = document.documentElement;
  root.style.setProperty('--video-slider-size', 'inherit');

  if (videoSlides.length > 0 && thumbnailSlider) {
    videoSlides.forEach(slide => {
      const isNextSlide = +slide.dataset.index === newIndex;
      const hasActiveClass = slide.classList.contains('slick-active') && (!oldIndex || !newIndex);

      if (isNextSlide || hasActiveClass) {
        const slideHeight = slide?.offsetHeight;
        const thumbnailsHeight = thumbnailSlider?.offsetHeight;

        root.style.setProperty('--video-slider-size', `${slideHeight + thumbnailsHeight}px`);
      } else {
        root.style.setProperty('--video-slider-size', 'inherit');
      }
    })
  }
  
  
};