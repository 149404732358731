import { toggleSearch, onSubmit, clearSearch, onInputFocus, setSearchState } from "@src/actions/search";

const initialState = {
    query: "",
    isOpen: false,
    isFocused: false,
    defaultItems: {
      products: [],
      blogs: [],
      groups: [],
    },
    items: {
      products: [],
      blogs: [],
      groups: [],
    },
    closestWordToQuery: "",
}

const searchSlice = (set, get) => ({
    ...initialState,

    toggleSearch: (override) => toggleSearch(set, override),

    onSubmit: (search) => onSubmit(set, search),

    clearSearch: (setValue) => clearSearch(set, setValue),

    onInputFocus: () => onInputFocus(set),

    setSearchState: (newState) => setSearchState(set, newState),
})

export default searchSlice;