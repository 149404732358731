import client from "@src/api/sanity/client";

export const fetchInstaPosts = async () => {
  try {
    const posts = await client.fetch(
      `*[_type == 'instaPost'][0...10] | order(timestamp desc) {
        ...,
        post{
          ...,
          asset->
        },
        productReference->{
          ...,
          variantColor[]->{
            color
          }
        }
      }`
    );
    return posts;
  } catch (error) {
    console.error("Error fetching Instagram posts:", error);
    throw error;
  }
};

export const fetchProductFromVariant = async (id, base64 = true) => {
  if (!id) return;
  
  let query = `*[_type == 'shopifyProduct' && shopifyId == $id][0] {
    shortTitle,
    slug {
      current
    },
    "productGroups": productGroups[]->{
      _type,
      slug {
        current
      }
    },
    _type
  }`;

  if (!base64) {
    query = `*[_type == 'shopifyProduct' && _id == $id][0] {
      shortTitle,
      slug {
        current
      },
      "productGroups": productGroups[]->{
        _type,
        slug {
          current
        }
      },
      _type,
      _id
    }`;
  }

  try {
    const products = await client.fetch(query, { id });
    
    return products;
  } catch (error) {
    console.error("Error fetching Shopify products:", error);
    throw error;
  }
};
