import React, { useEffect, useState } from "react"
import SearchItem from "../search-item"
import ColumnItem from "@components/rebrand/column-item"
import { ReactComponent as Spinner } from "@svgs/animated-spinner.svg"
import SearchSlider from "../search-slider"
import { useSearchStore } from "@src/hooks/useStore"

const SearchResults = ({ searchItems }) => {
  const [loading, isLoading] = useState(false)
  const { products, blogs, groups } = searchItems || []
  const { query, defaultItems } = useSearchStore(["query", "defaultItems"])

  // const defaultProducts = defaultItems.products || [];
  const defaultBlogs = defaultItems.blogs || [];
  const defaultGroups = defaultItems.groups || [];

  useEffect(() => {
    isLoading(true)
    setTimeout(() => isLoading(false), 1000)
  }, [query])

  return (
    <div className="grid grid-cols-12 gap-x-2.5 w-full overflow-hidden overflow-y-hidden max-md:gap-y-5 h-full">
      <ColumnItem
        className={
          "flex flex-col bg-light-gray w-full md:p-5 rounded-xl animate-slidein max-md:order-2 col-span-4 max-md:col-span-12"
        }
      >
        {loading ? (
          <Spinner className={"max-w-[100px] max-h-[100px]"} />
        ) : (
          <>
            <SearchItem
              heading={"Blogs"}
              items={blogs}
              query={query}
              defaultItems={defaultBlogs}
              length={3}
              mbLength={3}
              cols={1}
              className={"max-md:px-2.5 max-md:pt-2.5"}
            />
            <SearchItem
              heading={"Categories"}
              items={groups}
              query={query}
              defaultItems={defaultGroups}
              length={3}
              mbLength={3}
              cols={1}
              className={"max-md:px-2.5 max-md:pb-2.5"}
            />
          </>
        )}
      </ColumnItem>
      <ColumnItem className="w-full flex col-span-8 max-md:col-span-12">
        {loading ? (
          <Spinner className={"max-w-[100px] max-h-[100px]"} />
        ) : (
          <SearchSlider
            heading={"Products"}
            items={products}
          />
        )}
      </ColumnItem>
    </div>
  )
}

export default SearchResults
