export const wrapperClasses = {
    "desktop-mobile-opened": "border-b-1 border-gray border-solid last:border-b-0 max-h-full grow gap-y-5 p-10 !pb-10 max-md:!p-5",
    "desktop-mobile-closed": "border-b-1 border-gray border-solid last:border-b-0 max-h-fit gap-y-0 p-10 !pb-10 max-md:!p-5 ",
    "desktop-opened": "md:border-b-1 md:border-gray md:border-solid md:last:border-b-0 md:max-h-full md:grow md:gap-y-5 md:p-10 !pb-10",
    "desktop-closed": "md:border-b-1 md:border-gray md:border-solid md:last:border-b-0 md:max-h-fit md:gap-y-0 md:p-10 !pb-10",
    "mobile-opened": "max-md:border-b-1 max-md:border-gray max-md:border-solid max-md:last:border-b-0 max-md:max-h-9999 grow max-md:p-5 max-md:gap-y-5",
    "mobile-closed": "max-md:border-b-1 max-md:border-gray max-md:border-solid max-md:last:border-b-0 max-md:max-h-fit max-md:p-5 max-md:gap-y-0",
}

export const titleClasses = {
    "desktop-mobile-opened": "cursor-pointer pb-0 md:pt-5",
    "desktop-mobile-closed": "cursor-pointer pb-0 md:pt-5",
    "desktop-opened": "md:cursor-pointer md:pb-0",
    "desktop-closed": "md:cursor-pointer md:pb-0",
    "mobile-opened": "max-md:cursor-pointer max-md:pb-0",
    "mobile-closed": "max-md:cursor-pointer max-md:pb-0",
}

export const contentClasses = {
    "desktop-mobile-opened": "max-h-9999 visible",
    "desktop-mobile-closed": "max-h-0 invisible",
    "desktop-opened": "md:max-h-9999 md:visible",
    "desktop-closed": "md:max-h-0 md:invisible",
    "mobile-opened": "max-md:max-h-9999 max-md:visible",
    "mobile-closed": "max-md:max-h-0 max-md:invisible",
}