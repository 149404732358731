import React from 'react';
import getValue from 'get-value';
import { getBgColorFromHex } from '@utils/general';

const desktopColumnSizes = {
  '1/1': 'md:col-span-12',
  '1/2': 'md:col-span-6',
  '2/3': 'md:col-span-8',
  '1/3': 'md:col-span-4',
  '1/4': 'md:col-span-3',
  '1/6': 'md:col-span-2',
  '5/12': 'md:col-span-5',
  '7/12': 'md:col-span-7',
};

const mobileColumnSizes = {
  '1/1': 'col-span-12',
  '1/2': 'col-span-6',
  '2/3': 'col-span-8',
  '1/3': 'col-span-4',
  '1/4': 'col-span-3',
  '1/6': 'col-span-2',
  '5/12': 'col-span-5',
  '7/12': 'col-span-7',
};

const columnOrder = {
  'none': 'max-md:order-none',
  '1': 'max-md:order-0',
  '2': 'max-md:order-1',
  '3': 'max-md:order-2',
  '4': 'max-md:order-3',
  '5': 'max-md:order-4',
  '6': 'max-md:order-5',
};

const Column = ({ 
  className, 
  innerClassName, 
  colorSettings, 
  noPadding = false, 
  children, 
  verticalSpacing, 
  columnWidth, 
  columnWidthMobile, 
  orderMobile 
}) => {
  const desktopColumnSize = columnWidth ? getValue(desktopColumnSizes, columnWidth, { default: desktopColumnSizes['1/1'] }) : desktopColumnSizes['1/1'];
  const mobileColumnSize = columnWidthMobile ? getValue(mobileColumnSizes, columnWidthMobile, { default: mobileColumnSizes['1/1'] }) : mobileColumnSizes['1/1'];
  const orderClass = orderMobile ? getValue(columnOrder, orderMobile, { default: columnOrder['none'] }) : columnOrder['none'];

  return (
    <div className={`${mobileColumnSize} ${desktopColumnSize} ${className || ''} ${orderClass}`}>
      <div className={`flex flex-col w-full h-full shrink-0 ${noPadding ? '' : 'p-5 lg:p-10'} ${innerClassName || ''} ${verticalSpacing || 'md:justify-between'} gap-5 rounded-xl ${getBgColorFromHex(colorSettings?.bgColor)}`}>
        {children}
      </div>
    </div>
  );
};

export default Column;
