import React from 'react'
import Field from '../form/field'

const FieldGroup = ({ label, elements, name, type, required, register, errors, className, inputPostfix }) => {
  return (
    <div className={`${className || ''} p-5 flex flex-row gap-x-5 items-center`}>
      <p className={`text-sm leading-none font-medium font-owners ${errors[name] ? 'text-red-600' : ''}`}>{label}{required ? '*' : ''}:</p>
      {elements?.map((element, i) => (
          <Field 
          key={i}
          name={name}
          type={type}
          label={element?.text}
          placeholder="Ex. louise@gmail.com"
          register={register}
          required={required}
          errors={errors}
          value={element?.text}
          isGroup={true}
          inputPostfix={inputPostfix}
        />
      ))}
    </div>
  )
}

export default FieldGroup