import { getScript } from "@src/utils/getScript"
import React, { createContext, useContext, useEffect, useState } from "react"

export const StyluxContext = createContext(null)

export function useStylux() {
  const ctx = useContext(StyluxContext)

  if (!ctx) {
    throw new Error(
      "To use `useStylux`, a parent element must be wrapped with `StyluxProvider`"
    )
  }

  return ctx
}

export function StyluxProvider({ children }) {
  function getStyluxGlobal() {
    if (typeof window !== "undefined") {
      return window.Stylux ?? null
    }

    return null
  }

  const [loadingAndSetupState, setLoadingAndSetupState] = useState({
    loaded: false,
    loading: false,
    setup: false,
    Stylux: getStyluxGlobal(),
  })

  function loadStyluxGlobalScriptsOnce() {
    // SDK is already being loaded, has been loaded, or this is being run
    // on the server
    if (
      loadingAndSetupState.loading ||
      loadingAndSetupState.loaded ||
      typeof window === "undefined"
    ) {
      return
    }

    setLoadingAndSetupState({
      loaded: false,
      loading: true,
      setup: false,
      Stylux: getStyluxGlobal(),
    })

    getScript("https://sdk.stylux.io/js-sdk.js", () => {
      setLoadingAndSetupState({
        loaded: true,
        setup: false,
        loading: false,
        Stylux: getStyluxGlobal(),
      })
    })
  }

  useEffect(() => {
    async function setupStylux() {
      await loadingAndSetupState.Stylux.setup({
        merchantId: "8649cce8-1513-4d2c-87a4-0b310383f3d7",
        preload: false,

        apiKey:
          "STLX_0e236a9af25375769457242b41e8d3bd9bd9274d3ba7e2c3d1b71713962e",

        modal: {
          buttonStyles: {
            background: "rgb(68, 160, 67)",
            border: "0",
            "font-weight": "600",
            "font-family": "Owners Text, sans-serif",
            color: "black",
          },

          icons: [
            {
              type: "star",
              text: "One of a kind",
            },

            {
              type: "gift",
              text: "Great for gifting",
            },
          ],
        },
      })

      setLoadingAndSetupState({
        loaded: true,
        setup: true,
        loading: false,
        Stylux: getStyluxGlobal(),
      })
    }

    if (!loadingAndSetupState.setup && loadingAndSetupState.Stylux) {
      setupStylux()
    }
  }, [loadingAndSetupState.Stylux, loadingAndSetupState.setup])

  return (
    <StyluxContext.Provider
      value={{ state: loadingAndSetupState, loadStyluxGlobalScriptsOnce }}
    >
      {children}
    </StyluxContext.Provider>
  )
}
