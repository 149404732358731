import React, { useState } from "react";
import ScreenReaderText from "../screen-reader-text";

const ReadMore = ({ text, className }) => { 
  const [isReadMore, setIsReadMore] = useState(true); 
  const toggleReadMore = () => { 
    setIsReadMore(!isReadMore); 
  };
  
  return (
    <div className={className}>
      <p>
        <ScreenReaderText srText={'Product Summary: ' + text} defaultText={isReadMore && text.length > 160 ? text?.slice(0, 140) + '...' : text} />
        {text.length > 160 && 
          <span onClick={toggleReadMore} className={`pl-2.5 underline cursor-pointer`}> 
            {isReadMore ? "Read More" : "Show Less"} 
          </span>
        }
      </p> 
    </div>
  ); 
};

export default ReadMore;
