import React from 'react';

const ScreenReaderText = ({ className, labelledbyId, srText, defaultText, children, ariaHidden = true, tabIndex, onClick, onKeyDown }) => {
  return (
    <>
      <span className="sr-only" id={labelledbyId} >{srText}</span>
      {(defaultText || children) 
        && <span aria-hidden={ariaHidden} tabIndex={tabIndex} className={className || ''} onClick={onClick} onKeyDown={onKeyDown}>
          {defaultText}
          {children}
        </span>
      }
    </>
  );
};

export default ScreenReaderText;