import React from "react";
import Vimeo from '@u-wave/react-vimeo'
import { isNumeric } from '@utils/general'
import { getSpeedsizeProps } from "@src/utils/image";

export const getVideoIdFromUrl = (url) => {
  try {
    const videoUrl = new URL(url)
    return videoUrl.searchParams.get("v")
  } catch (error) {
    console.error("Invalid URL:", error)
    return null
  }
}

export const buildIframe = (videoId, controls, muted, loop, autoplay) => {
  const autoplayParam = autoplay ? "&autoplay=1" : ""
  const controlsParam = controls ? "" : "&controls=0"
  const mutedParam = muted ? "&mute=1" : ""
  const loopParam = loop ? `&loop=1&playlist=${videoId}` : ""

  const src = `https://www.youtube.com/embed/${videoId}?${autoplayParam}${mutedParam}${controlsParam}${loopParam}`

  return (
    <iframe
      className="bg-white absolute top-0 left-0 bottom-0 z-20 h-full w-full rounded-xl"
      frameBorder="0"
      allow="accelerometer; autoplay; controls; loop; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      src={src}
      title="Product Video"
    ></iframe>
  )
}

const buildVideoTag = (video, controls, autoplay, muted, loop, size) => {
  const videoSettings = {
    controls,
    autoPlay: autoplay,
    muted,
    loop,
    className: 'bg-black absolute top-0 left-0 bottom-0 z-20 w-full h-full rounded-xl',
  }

  const sourceSettings = {
    src: "https://cdn.speedsize.com/62dcb8e7-50f0-4e8d-a01e-6b69525240c3/" + video?.asset?.url + `/f_auto,imdt,${getSpeedsizeProps(size) || ''}`,
    mimeType: video?.asset?.mimeType || 'video/mp4'
  }

  return (
    <video {...videoSettings}>
      <source {...sourceSettings} />
      Your browser does not support the video tag.
    </video>
  )
}

export const getVideoTypeAndData = (sanity, youtube, vimeo) => {
  if (sanity?.asset?.url && sanity)
    return { type: 'sanity', data: sanity };
  else if (youtube)
    return { type: 'youtube', data: youtube };
  else if (vimeo)
    return { type: 'vimeo', data: vimeo };

  return null;
}

export const renderVideo = (video, autoplay, muted, controls, loop, isLoaded, placeholderImage, size) => {
  const type = video?.type;
  const data = video?.data;
  
  switch (type) {
    case "sanity":
      return buildVideoTag(data, controls || !autoplay, autoplay || (isLoaded && placeholderImage), muted || autoplay, loop, size);
    case "youtube":
      return buildIframe(getVideoIdFromUrl(data), controls, autoplay || muted, loop, placeholderImage || autoplay);
    case "vimeo":
      return (
        <Vimeo
          className="bg-white absolute top-0 left-0 bottom-0 z-20 h-fit-content w-full rounded-xl"
          video={isNumeric(data) ? data : ""}
          autoplay={autoplay || (isLoaded && placeholderImage)}
          muted={muted || autoplay}
          controls={controls || !autoplay}
          background={autoplay}
          responsive
          loop={loop}
          showPortrait={false}
          showTitle={false}
        />
      );
    default:
      return (
        <div className="w-full h-full rounded-xl bg-gray text-white text-2xl font-semibold flex flex-col gap-y-5 justify-center items-center">
          <svg fill="#fff" height="80px" width="80px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 206.559 206.559" xmlSpace="preserve">
            <path d="M0,103.279c0,56.948,46.331,103.279,103.279,103.279s103.279-46.331,103.279-103.279S160.228,0,103.279,0
              S0,46.331,0,103.279z M170,103.279c0,36.79-29.931,66.721-66.721,66.721c-11.765,0-22.821-3.072-32.429-8.439L161.56,70.85
              C166.927,80.458,170,91.514,170,103.279z M103.279,36.559c11.765,0,22.821,3.072,32.429,8.439l-90.709,90.711
              c-5.368-9.608-8.44-20.664-8.44-32.43C36.559,66.489,66.489,36.559,103.279,36.559z"/>
          </svg>      
          No video available!
        </div>
      );
  }
};
