import React from 'react'
import { Helmet } from 'react-helmet';

const Fatmedia = () => {
  return (
    <Helmet>
    <script type="text/javascript">
    {`
      window.glk_program = "6074198";
    `}
    </script>
    <script type="text/javascript">
      {`function docReady(e){"complete"===document.readyState||"interactive"===document.readyState?setTimeout(e,1):document.addEventListener("DOMContentLoaded",e)}docReady(function(){var
      e=document.createElement("script");e.async=!0,e.type="text/javascript",e.src="https://analytics.fatmedia.io/";var
      t=document.getElementsByTagName("body");t&&t[0]&&t[0].appendChild(e)});`}
    </script>
  </Helmet>
  )
}

export default Fatmedia