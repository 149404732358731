import { formatPrice } from "@src/utils/products/pricing";
import { getRegionCurrency } from "@src/utils/region";

export const getPrice = price =>
    formatPrice({
      price,
      minimumFractionDigits: Number.isInteger(parseFloat(price)) ? 0 : 2,
      currency: getRegionCurrency(),
    })

export const discountMap = ({ code, title, discountedAmount }) => {
  const { amount } = discountedAmount;
  const displayAmount = getPrice(parseFloat(amount));
  
  return { displayAmount, amount: parseFloat(amount), code: code || title };
};

export const getDiscounts = (cart) => {
  const { discountAllocations = [], lines = [] } = cart

  const isScriptDiscount = discountAllocations?.filter(({ discountedAmount: { amount } }) => parseFloat(amount) === 0).length > 0;
  
  if (discountAllocations?.length > 0 && !isScriptDiscount) {
    return discountAllocations.map(discountMap);
  }

  return lines?.flatMap(({ discountAllocations, id }) =>
    discountAllocations?.map(discountMap).map(discount => ({ id, ...discount }))
  ) || [];
};

export const getDiscountLabel = discounts => discounts?.length > 0 ? discounts[0]?.code || discounts[0]?.title : '';

export const getDiscountAmount = (discounts) => {
  return discounts?.length > 1 
    ? discounts?.reduce((a, b) => a + b.amount, 0)
    : discounts[0]?.amount;
};

export const getDiscountDisplayedAmount = (discounts) => {
  return discounts?.length > 1 
    ? getPrice(parseFloat(discounts?.reduce((a, b) => a + b.amount, 0)))
    : discounts[0]?.displayAmount;
};

// export const checkFreeShippingDiscount = (cart) => {
//   const { discountAllocations = [], discountCodes = [], lines = [] } = cart
//   console.log()
//   const isFreeShippingDiscount = 
//     discountCodes?.filter(({ applicable }) => applicable).length > 0 
//     && lines?.filter(({ discountAllocations }) => !discountAllocations.length > 0).length > 0
//     && !discountAllocations?.length > 0;

//   return isFreeShippingDiscount
// }