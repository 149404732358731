import React, { useEffect } from "react"
import UpsellItem from './item'
import Slider from 'react-slick'
import { ReactComponent as ArrowDown } from "@svgs/arrow-down.svg";
import { useCartStore } from "@src/hooks/useStore";

interface SliderArrowComponentProps {
  rotate?: boolean
  onClick?: () => void
  onOtherClick?: () => void
  customClassName?: string
}

export const SliderArrowComponent = ({ rotate = false, onClick, customClassName, onOtherClick }: SliderArrowComponentProps) => {
	const classList = `absolute z-10 p-2 right-[10px] bg-soft-black flex justify-end top-1/4 -translate-y-1/4 cursor-pointer rounded-md ${rotate ? 'right-[45px]' : ''} ${customClassName || ""}`
	const onArrowClick = () => {
    onClick && onClick();
    onOtherClick && onOtherClick();
  }
  return (
		<button onClick={onArrowClick} className={classList} tabIndex={0} aria-label={rotate ? 'Previous Upsell Product' : 'Next Upsell Product'} role={'button'}>
      <ArrowDown className={`fill-white ${rotate ? 'rotate-90' : 'rotate-[270deg] scale-x-[-1]'}`} />
		</button>
	)
}

export default function CartUpsell() {
  const { upsells } = useCartStore(["upsells"]);

  if (!upsells || upsells.length === 0) return <></>

  let settings = {
    dots: false,
    infinite: true,
    autoplay: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
		nextArrow: <SliderArrowComponent />,
		prevArrow: <SliderArrowComponent rotate={true} />,
    className: 'cart-upsell-slider'
  }

  return (
    <div className="relative">
      <Slider {...settings}>
        {upsells.map((product: any) => (
          <UpsellItem 
            key={product?._id}
            product={product} 
          />
        ))}
      </Slider>
    </div>
  )
}