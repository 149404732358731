export const setQuickViewOpen = (set, open) => {
  set(state => ({
    quickViewStore: {
      ...state.quickViewStore,
      open,
    },
  }))
}

export const setQuickViewType = (set, type) => {
  set(state => ({
    quickViewStore: {
      ...state.quickViewStore,
      type,
    },
  }))
}

export const setQuickViewProduct = (set, product) => {
  set(state => ({
    quickViewStore: {
      ...state.quickViewStore,
      quickViewProduct: product,
    },
  }))
}

export const setQuickViewVariant = (set, variant) => {
  set(state => ({
    quickViewStore: {
      ...state.quickViewStore,
      quickViewVariant: variant,
    },
  }))
}

export const setQuickViewProductList = (set, productList) => {
  set(state => ({
    quickViewStore: {
      ...state.quickViewStore,
      quickViewProductList: productList,
    },
  }))
}
