import imageUrlBuilder from "@sanity/image-url"
import { getSpeedsizeProps } from "@src/utils/image"

const dimensions = [320, 654, 768, 1024, 1366, 1500, 1800];

const getMaxValueIndex = (width) => {
  const maxValue = dimensions.find(w => w >= width) || dimensions[dimensions.length - 1];
  const maxValueIndex = dimensions.indexOf(maxValue) + 1 || dimensions.length - 1;

  return maxValueIndex;
};

const calculateHeight = (newWidth, originalWidth, originalHeight) => {
  if (!newWidth) return originalHeight;

  const aspectRatio = originalWidth / originalHeight;
  const newHeight = newWidth / aspectRatio;

  return Math.round(newHeight);
}

const getSpeedSizeSource = (source) => {
  const domain = "https://cdn.speedsize.com/";
  const projectId = "62dcb8e7-50f0-4e8d-a01e-6b69525240c3";

  return domain + projectId + '/' + source + '/';
}

export const getImageSource = (image, imageObject, width = 600, height = 600) => {
  let initialSrc;

  if (imageObject?.image[0]?._type === "shopifyImage" || image?._type === "shopifyImage") {
    initialSrc = getSpeedSizeSource(imageObject?.image[0]?.shopifyURL || image?.shopifyURL);
  } else {
    const sanityConfig = { projectId: process.env.SANITY_PROJECT_ID || 'yt8n50id', dataset: process.env.GATSBY_SANITY_DATASET || "production-new"}

    const imageData = image?._type === "customImage"
      ? imageObject.image?.asset || imageObject.image[0]?.asset
      : typeof image?.images?.fallback !== "undefined"
        ? image
        : image?.asset

    const imageUrl = () => {
      return imageUrlBuilder(sanityConfig).image(imageData).url() || imageData?.images?.fallback?.src
    }

    initialSrc = getSpeedSizeSource(imageUrl());
  }

  

  const { src, srcSet } = getImageSourceSet(initialSrc, width, height);
  const sizes = getImageSizes(width);

  return { src, srcSet, sizes };
}

const getImageSourceSet = (source, width, height) => {
  const maxValueIndex = getMaxValueIndex(width);

  const sizeParams = (customWidth) => {
    const newWidth = customWidth || width;
    const newHeight = calculateHeight(customWidth, width, height);

    return getSpeedsizeProps({width: newWidth, height: newHeight}) || ''
  };

  const src = source + sizeParams();
  const srcSet = dimensions
    .filter(w => dimensions.indexOf(w) < maxValueIndex)
    .map(w => `${source + sizeParams(w)} ${w}w`)
    .join(', ');

  return { src, srcSet };
}

const getImageSizes = (maxWidth) => {
  const maxValueIndex = getMaxValueIndex(maxWidth)

  const sizes = dimensions.slice(0, maxValueIndex).map((width, index, arr) => {
    if (index === 0) {
      return `(max-width: ${arr[0]}px) ${arr[0]}px`;
    } else {
      const minWidth = arr[index - 1];
      return `(min-width: ${minWidth + 1}px) and (max-width: ${width}px) ${width}px`;
    }
  });

  if (maxValueIndex === dimensions.length - 1) {
    sizes.push(`(min-width: ${dimensions[maxValueIndex] + 1}px) ${dimensions[maxValueIndex]}px`);
  }

  return sizes.join(', ');
};