import React from "react"
import { formatPrice } from "@utils/products/pricing"
import QuantityInput from "../quantity-input"

const WarrantyDetails = props => {
  const { lineItem, checkoutCurrency, onRemove, onUpdateQuantity, productUnits } = props

  const { variant, quantity } = lineItem

  const lineItemCurrencyMatchesCheckout =
    checkoutCurrency === variant?.price?.currencyCode
  const lineItemPrice = lineItemCurrencyMatchesCheckout
    ? variant?.price?.amount
    : variant?.price

  const handleRemove = (evt) => {
    onRemove(evt, lineItem);
  }

  return (
    <div className="flex flex-col ml-24 mt-2 w-full">
      <div className="ml-2">
        <div className="pt-2 flex justify-between flex-wrap">
          <div className="w-2/3">
            <p className="font-medium mb-2 md:text-base text-sm">{lineItem.title}</p>
            <p className="text-xs truncate">{lineItem.variant.title}</p>
            <div className="mt-2">
              {productUnits > 1 && <QuantityInput
                currentQuantity={quantity}
                maxQuantity={productUnits}
                onUpdate={onUpdateQuantity}
              />}
            </div>
          </div>
          <div className="flex items-center justify-center">
            <div className="w-6 h-6">
              <img src={lineItem.variant.image.src} className="w-full h-full" />
            </div>
          </div>
          <div className="flex flex-col justify-between items-end">
            <button
              className="ml-auto disabled:opacity-50 pt-2"
              onClick={handleRemove}
            >
              <span className="hidden">Remove</span>
              <span>
                <svg
                  width="15"
                  height="15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.854 13.082L2.056 2.284M2.056 13.082L12.854 2.284"
                    stroke="#000"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>
              </span>
            </button>
            <p className="mt-auto text-sm antialiased text-dark-gray font-medium">
              {formatPrice({
                price: lineItemPrice,
                currency: checkoutCurrency,
                minimumFractionDigits: 2
              })}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default WarrantyDetails
